<template>
	<div
	class="coreScheme"
	v-if="core.status !== 'cancelled'"
	>
		<div class="coreScheme__number">
			<p
			class="coreScheme__title"
			@click="assignAllLayers()"
			>
				Carotte {{ core.number }}
			</p>

			<v-icon @click="focusOn()">
				mdi-crosshairs-gps
			</v-icon>
		</div>

		<b
		class="coreScheme__priority"
		@click="togglePriority()"
		>
			Priorité Eurofins : {{ core.priorityCode }}
		</b>

		<div
		class="coreScheme__layer"
		:style="
			'background-color: ' + (0 === layer.number % 2 ? 'white' : '#ddd')
		"
		v-for="layer in layers"
		:key="core.id + layer.id"
		>
			<div
			class="layerName"
			@click="handleLayerClick(layer.id)"
			>
				C{{ layer.number }} {{ layer.materialCategory || layer.material? "-" : "" }} {{ layer.materialCategory? layer.materialCategory.name : "" }} {{ layer.material? layer.material.name : "" }} - {{ layer.thickness }}mm
			</div>

			<div
			class="coreScheme__prestations"
			:style="'height:' + layer.thickness * layerCoef + 'px'"
			>
				<v-tooltip
				v-for="prestation in layer.prestations"
				:key="prestation.id"
				top
				>
					<template v-slot:activator="{ on, attrs }">
						<v-chip
						@dblclick="removePrestation(prestation, layer.id)"
						:color="getColor(prestation)"
						class="mb-1"
						x-small
						v-bind="attrs"
						v-on="on"
						>
							<b> {{ resolvePrestationType(prestation.prestation) }} : </b>

							{{
								prestation.prestation.name.length > 31
									? prestation.prestation.name.substring(0, 30) + "..."
									: prestation.prestation.name
							}}
						</v-chip>
					</template>

					<span>{{ prestation.prestation.name }}</span>
				</v-tooltip>
			</div>
		</div>
	</div>
</template>

<script>
import areiaUtils from "@/utils/areia.utils";
import eurofinsUtils from "@/utils/eurofins.utils";

export default {
	props: [
		"value", "prestations", "provider", "layerCoef", "layerPrestations"
	],
	data(){
		return {
			core: {
				number: 0,
				priorityCode: ""
			},
			layers: [],
			coreThickness: 0
		};
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(){
				this.core = this.value;
				this.filterLayers();
			}
		}
	},
	methods: {
		addAllPrestations(){
			this.core.layers.forEach(layer => {
				this.addPrestations(
					layer.id,
					this.prestations.map(p => {
						return {
							prestation: {...p, provider: this.provider},
							layer: {},
							result: []
						};
					})
				);
			});
		},
		/** available priority codes are referenced in eurofins edi documentation */
		togglePriority(){
			this.core.priorityCode =
        "STD" === this.core.priorityCode ? "RUSH" : "STD";
			this.emitChange();
		},
		emitChange(){
			let core = this.core;
			core.layers = this.layers;

			this.$emit("input", core);
		},
		handleLayerClick(id){
			this.addPrestations(
				id,
				this.prestations.map(p => {
					return {
						prestation: {...p, provider: this.provider},
						layer: {},
						result: []
					};
				})
			);
		},
		addPrestations(layerId, prestations){
			const layerIndex = this.layers.findIndex(l => l.id === layerId);

			if(-1 === layerIndex) return;
			const layer = this.layers[layerIndex];
			let selectedPrestations = prestations.map(p => {
				return {
					...p,
					layer: layer
				};
			});
			let newPrestations = [];
			selectedPrestations.forEach(selectedPrestation => {
				if(
					layer.prestations.filter(e => {
						return e.prestation.code === selectedPrestation.prestation.code;
					}).length === 0
				) newPrestations.push(selectedPrestation);
			});

			layer.prestations = [
				...this.layers[layerIndex].prestations,
				...newPrestations
			];

			this.layers[layerIndex].prestations.prestations = layer.prestations;

			this.emitChange();
		},
		removePrestation(prestation, layerId){
			const layerIndex = this.layers.findIndex(l => l.id === layerId);

			if(-1 === layerIndex) return;
			const layer = this.layers[layerIndex];

			const prestationIndex = layer.prestations.findIndex(
				p => p.prestation.id === prestation.prestation.id
			);
			layer.prestations.splice(prestationIndex, 1);
			this.emitChange();
		},
		resolvePrestationType(prestation){
			let res = "";
			switch (prestation.provider.name){
				case "EUROFINS":
					res = this.resolveEUROFINSPrestationType(prestation);
					break;
				default:
					res = this.resolveAREIAPrestationType(prestation);
					break;
			}

			return res;
		},
		resolveEUROFINSPrestationType(prestation){
			if(eurofinsUtils.hasAsbestos([prestation])) return "Am";
			else if(eurofinsUtils.hasPAH([prestation])) return "HAP";
		},
		resolveAREIAPrestationType(prestation){
			if(areiaUtils.hasAsbestos([prestation])) return "Am";
			else if(areiaUtils.hasPAH([prestation])) return "HAP";
			else if(areiaUtils.hasTH([prestation])) return "HCT";
		},
		async filterLayers(){
			if(undefined !== this.$store.state.cores.all[this.core.id]){
				this.layers = this.$store.state.cores.all[this.core.id];
				this.emitChange();
				return;
			}
			else {
				let finalLayers = [];

				this.$api.coreLayers.get(this.core.id).then(coreLayers => {
					coreLayers.forEach(coreLayer => {
						finalLayers.push({
							...coreLayer,
							prestations: coreLayer.layerPrestations
						});
					});

					finalLayers.sort((a, b) => a.number - b.number);
					this.layers = finalLayers;
					this.emitChange();
				});
			}
		},
		focusOn(){
			this.$emit("focusOnCore", this.core);
		},
		getColor(prestation){
			if(prestation.prestation.provider.name === "EUROFINS"){
				return "#FCF0CC";
			}
			else if(prestation.prestation.provider.name === "AREIA"){
				return "#BBDBB4";
			}
			else if(prestation.prestation.provider.name === "AD-LAB"){
				return "#BEBEBE";
			}
		},
		// getColor(prestationType) {
		//   let color = "";

		//   switch (prestationType) {
		//     case "HAP":
		//       color = "#E8C547";
		//       break;
		//     case "Am":
		//       color = "#5C80BC";
		//       break;
		//     case "HCT":
		//       color = "#6AD5CB";
		//       break;

		//     default:
		//       break;
		//   }
		//   return color;
		// },
		assignAllLayers(){
			this.layers.forEach(l => this.handleLayerClick(l.id));
		}
	},
	beforeDestroy(){
		// set le layer dans le store
		if(undefined === this.$store.state.cores.all[this.core.id]){
			this.$store.commit("cores/addCore", {
				core: this.layers,
				coreIdIndex: this.core.id
			});
		}
	},
	mounted(){
		this.$root.$on("addPrestations", this.addAllPrestations);
	}
};
</script>

<style lang="scss" scoped>
.layerName {
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: #ddd;
  }
}

.coreScheme {
  border: 1px solid black;
  width: 15%;

  &__priority {
    cursor: pointer;
    text-align: center;
  }

  &__prestations {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__number {
    text-align: center;
    font-weight: bold;
  }

  &__number {
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  &__layer {
    border-top: 1px solid black;
    min-height: 49px;
    padding: 5px;
  }
}
</style>
