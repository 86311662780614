<template>
	<div
	id="campaign"
	class="tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]"
	>
		<HeaderSlot
		:toBackButton="{ name: 'project', params: { id: campaign.workspace.id } }"
		:title="$cap(campaign.name)"
		:subTitle="
			'Gérer la campagne ' + 
				(
					campaign.type === 'auscultation'? 
						'd\'auscultation' : 
						'de carottage'
				)
		"
		/>

		<div
		class="tw-grow tw-w-full tw-overflow-y-auto tw-w-full tw-flex tw-flex-col lg:tw-flex-row tw-gap-[5px] tw-pr-[5px]"
		>
			<div
			class="tw-basis-full lg:tw-basis-[50%] tw-flex tw-flex-col tw-gap-[5px]"
			v-if="$hasRight('campaigns.editCampaignInformation') || $hasRight('campaigns.addOtherStudyManager')"
			>
				<v-card
				outlined
				v-if="$hasRight('campaigns.editCampaignInformation')"
				>
					<v-card-title>
						<b>Informations de la campagne</b>

						<v-spacer/>
					</v-card-title>

					<v-card-subtitle/>

					<v-form
					class="px-4"
					ref="campaignInfoForm"
					>
						<v-text-field
						label="Nom de la campagne*"
						:rules="[$rules.required, $rules.min5Chars]"
						:validate-on-blur="true"
						:value="campaign.name"
						v-model="form.name"
						outlined
						clearable
						dense
						tabindex="1"
						/>

						<v-textarea
						label="Description"
						outlined
						clearable
						counter="500"
						v-model="form.globalComment"
						:rules="[$rules.max500]"
						dense
						no-resize
						rows="3"
						:validate-on-blur="true"
						/>

						<v-text-field
						label="Référence dossier"
						:value="campaign.nextroadRef"
						v-model="form.nextroadRef"
						outlined
						clearable
						dense
						tabindex="2"
						:validate-on-blur="true"
						/>

						<v-text-field
						label="Référence commande client"
						:value="campaign.customerOrderRef"
						v-model="form.customerOrderRef"
						outlined
						clearable
						dense
						tabindex="3"
						:validate-on-blur="true"
						/>

						<v-row>
							<v-col
							cols="12"
							md="6"
							>
								<v-menu
								transition="scale-transition"
								offset-y
								min-width="auto"
								:close-on-content-click="false"
								ref="menu"
								v-model="menu"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-combobox
										v-model="form.startDate"
										label="Date de début"
										prepend-inner-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
										outlined
										:rules="[
											$rules.earlierDate(form.startDate, form.endDate)
										]"
										:validate-on-blur="true"
										clearable
										tabindex="4"
										dense
										append-icon=""
										>
											<template v-slot:selection>
												{{ moment(form.startDate).format("Do MMMM YYYY") }}
											</template>
										</v-combobox>
									</template>

									<v-date-picker
									v-model="form.startDate"
									no-title
									scrollable
									@change="menu = false"
									/>
								</v-menu>
							</v-col>

							<v-col
							cols="12"
							md="6"
							>
								<v-menu
								transition="scale-transition"
								offset-y
								min-width="auto"
								:close-on-content-click="false"
								ref="menu2"
								v-model="menu2"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-combobox
										v-model="form.endDate"
										label="Date de fin"
										prepend-inner-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
										outlined
										:rules="[$rules.laterDate(form.startDate, form.endDate)]"
										:validate-on-blur="true"
										clearable
										tabindex="5"
										dense
										append-icon=""
										>
											<template v-slot:selection>
												{{ moment(form.endDate).format("Do MMMM YYYY") }}
											</template>
										</v-combobox>
									</template>

									<v-date-picker
									v-model="form.endDate"
									no-title
									scrollable
									@change="menu2 = false"
									/>
								</v-menu>
							</v-col>
						</v-row>

						<h4 v-if="campaign.type === 'coring'">
							Préfixe(s) du nom des carottes :
						</h4>

						<p
						class="text--secondary text-body-2 mb-0"
						v-if="
							campaign.status === 'awaiting_validation' &&
								0 < form.displayNamePrefixes.length &&
								campaign.type === 'coring'
						"
						>
							Attention, si vous supprimez un préfixe déjà attribué à une ou
							plusieurs carottes, il sera également supprimé de celle(s)-ci.
						</p>

						<div
						class="pb-2"
						v-if="
							0 < form.displayNamePrefixes.length &&
								campaign.type === 'coring'
						"
						>
							<v-chip
							small
							v-for="prefix in form.displayNamePrefixes"
							:key="prefix.id"
							class="prefix-chip"
							:close="campaign.status === 'awaiting_validation'"
							@click:close="removePrefix(prefix.id)"
							>
								{{ prefix.name.toUpperCase() }}
							</v-chip>
						</div>

						<div v-else>
							<p v-if="campaign.type === 'coring'">
								Vous n'avez pas de préfixes définis sur cette campagne.
							</p>
						</div>

						<div
						class="new-prefix"
						v-if="campaign.status === 'awaiting_validation'"
						>
							<v-text-field
							label="Ajouter un préfixe"
							class="new-prefix-input"
							dense
							outlined
							:rules="[$rules.max255]"
							v-model="form.newPrefix"
							@keyup.enter="addPrefix"
							/>

							<ButtonSlot
							@click="addPrefix"
							_theme="light-gray"
							>
								Ajouter
							</ButtonSlot>
						</div>

						<v-card-title class="px-0">
							<b>Informations techniques</b>

							<v-spacer/>
						</v-card-title>

						<v-select
						v-if="campaign.type === 'coring'"
						:items="coringTypes"
						tabindex="6"
						item-text="name"
						item-value="value"
						label="Type de carottage*"
						:rules="[$rules.required]"
						:validate-on-blur="true"
						outlined
						:value="campaign.coringType"
						v-model="form.coringType"
						dense
						attach
						/>

						<div class="tw-grid tw-grid-cols-12 tw-grid-rows-1 tw-gap-[5px]">
							<div
							:class="{
								'tw-col-span-6': campaign.status === 'awaiting_validation',
								'tw-col-span-12': campaign.status !== 'awaiting_validation'
							}"
							>
								<v-text-field
								v-if="campaign.type === 'coring'"
								tabindex="7"
								label="Épaisseur probable des carottes (en mm)"
								outlined
								clearable
								:value="campaign.predictableThickness"
								v-model="form.predictableThickness"
								:rules="[$rules.onlyNumbers]"
								:validate-on-blur="true"
								dense
								/>
							</div>

							<div
							class="tw-col-span-6 tw-flex tw-justify-center tw-gap-[5px]"
							v-if="
								campaign.type === 'coring' &&
									campaign.status === 'awaiting_validation'
							"
							>
								<label
								dir="rtl"
								for="switch-DirMethodology"
								class="tw-text-[rgba(0,0,0,.6)] tw-select-none tw-h-[40px] tw-align-middle tw-leading-[40px] tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis"
								>
									Méthodologie DIR :
									{{ this.form.isDirMethodology === true ? "Oui" : "Non" }}
								</label>

								<v-switch
								id="switch-DirMethodology"
								v-model="form.isDirMethodology"
								class="methodology"
								/>
							</div>
						</div>

						<v-autocomplete
						tabindex="8"
						outlined
						label="Système géoréférentiel*"
						:items="projectingSystems"
						item-text="name"
						item-value="val"
						v-model="form.projectingSystem"
						clearable
						dense
						placeholder="L93"
						attach
						/>

						<v-textarea
						v-if="campaign.type === 'coring'"
						tabindex="9"
						label="Note pour le(s) technicien(s)"
						outlined
						clearable
						counter="500"
						no-resize
						rows="3"
						:value="campaign.technicianNote"
						v-model="form.technicianNote"
						dense
						:rules="[$rules.max500]"
						:validate-on-blur="true"
						/>

						<v-select
						v-if="campaign.type === 'auscultation'"
						:disabled="!$hasRight('campaigns.editCampaignBtn')"
						:items="operatingModes"
						item-text="name"
						item-value="value"
						label="Mode opératoire de relevé de dégradations"
						outlined
						clearable
						v-model="form.operatingMode"
						tabindex="10"
						dense
						attach
						/>

						<v-textarea
						:disabled="!$hasRight('campaigns.editCampaignBtn')"
						v-if="
							campaign.type === 'auscultation' &&
								form.operatingMode === 'not_standard'
						"
						label="Commentaire sur le mode opératoire"
						outlined
						clearable
						counter="500"
						no-resize
						rows="3"
						:value="campaign.operatingModeComment"
						v-model="form.operatingModeComment"
						dense
						:rules="[$rules.max500]"
						:validate-on-blur="true"
						/>
					</v-form>

					<v-card-actions>
						<v-spacer/>

						<HighButtonSlot
						:_loading="loadingButton.informations"
						class="mb-3 mr-2"
						@click="updateCampaignInformations('campaignInfoForm')"
						>
							Sauvegarder les modifications
						</HighButtonSlot>
					</v-card-actions>
				</v-card>

				<v-card
				outlined
				v-if="
					projectOrganizationId === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3' && $hasRight('campaigns.addOtherStudyManager')
				"
				>
					<v-card-title>
						<b>Diffusion</b>

						<v-spacer/>
					</v-card-title>

					<v-card-subtitle class="pb-0">
						Chargés d'affaires ayant actuellement un droit de modification sur
						cette campagne.
					</v-card-subtitle>

					<ManageCampaignUsers
					:users="diffusedStudyManagers"
					:organization="projectOrganizationId"
					@userRemoved="refreshUsers"
					/>

					<v-card-subtitle>
						Selectionnez les chargés d'affaires à qui vous souhaitez donner un
						droit de modification sur cette campagne :
					</v-card-subtitle>

					<v-form
					class="px-4"
					ref="diffusionForm"
					>
						<v-autocomplete
						tabindex="15"
						label="Filtrer les chargés d'affaires par agence"
						outlined
						dense
						clearable
						item-text="name"
						item-value="id"
						prepend-inner-icon="mdi-account-search"
						:items="agencies"
						v-model="diffusionCard.agencyIds"
						@change="handleAgenciesAndSitesChange"
						multiple
						small-chips
						deletable-chips
						:disabled="
							diffusionCard.siteIds.length > 0 &&
								diffusionCard.agencyIds.length === 0
						"
						attach
						/>

						<v-autocomplete
						tabindex="16"
						label="Filtrer les chargés d'affaires par site"
						outlined
						dense
						clearable
						item-text="name"
						item-value="id"
						prepend-inner-icon="mdi-account-search"
						:items="sites"
						v-model="diffusionCard.siteIds"
						@change="handleAgenciesAndSitesChange"
						multiple
						small-chips
						deletable-chips
						attach
						/>

						<v-autocomplete
						tabindex="17"
						label="Chargé(s) d'affaires"
						outlined
						dense
						clearable
						v-model="form.campaignMembers"
						:items="members"
						item-text="name"
						item-value="id"
						prepend-inner-icon="mdi-account-search"
						multiple
						small-chips
						deletable-chips
						attach
						/>
					</v-form>

					<v-card-actions>
						<v-spacer/>

						<HighButtonSlot
						:_loading="loadingButton.users"
						class="mb-3 mr-2"
						@click="updateDiffusionMembers('diffusionForm')"
						>
							Ajouter les utilisateurs
						</HighButtonSlot>
					</v-card-actions>
				</v-card>
			</div>

			<div
			class="tw-basis-full lg:tw-basis-[50%] tw-flex tw-flex-col tw-gap-[5px]"
			>
				<v-card
				outlined
				v-if="$hasRight('campaigns.addMember')"
				>
					<v-card-title>
						<b>Votre équipe</b>

						<v-spacer/>
					</v-card-title>

					<v-card-subtitle/>

					<v-form
					class="px-4"
					ref="usersForm"
					>
						<v-autocomplete
						label="Client principal*"
						v-model="userForm.mainCustomerId"
						:items="customers"
						item-text="name"
						item-value="id"
						outlined
						:loading="loading"
						dense
						tabindex="11"
						:rules="[$rules.required]"
						:validate-on-blur="true"
						attach
						/>

						<v-autocomplete
						:label="
							form.coringType === 'CS'
								? 'Chargé d\'affaires référent'
								: 'Chargé d\'affaires référent*'
						"
						v-model="userForm.studyManagerId"
						:items="studyManagers"
						item-text="name"
						item-value="id"
						outlined
						:loading="loading"
						dense
						tabindex="12"
						:rules="
							this.form.coringType !== 'CS'
								? [$rules.required]
								: [
									$rules.atLeastOneEngineerOrStudyManager(
										this.userForm.engineerId,
										this.userForm.studyManagerId
									)
								]
						"
						:validate-on-blur="true"
						attach
						/>

						<v-autocomplete
						label="Ingénieur"
						v-model="userForm.engineerId"
						:items="engineers"
						item-text="name"
						item-value="id"
						outlined
						:loading="loading"
						clearable
						dense
						tabindex="13"
						:rules="
							form.coringType === 'CS'
								? [
									$rules.atLeastOneEngineerOrStudyManager(
										this.userForm.engineerId,
										this.userForm.studyManagerId
									)
								]
								: []
						"
						attach
						/>

						<v-autocomplete
						v-if="campaign.type === 'coring'"
						label="Technicien(s)*"
						v-model="userForm.technicianIds"
						:items="technicians"
						item-text="name"
						item-value="id"
						outlined
						:loading="loading"
						multiple
						small-chips
						dense
						tabindex="14"
						:rules="[$rules.atLeastOneTechnician]"
						:validate-on-blur="true"
						clearable
						deletable-chips
						attach
						/>
					</v-form>

					<v-card-actions>
						<v-spacer/>

						<HighButtonSlot
						:_loading="loadingButton.users"
						class="mb-3 mr-2"
						@click="updateCampaignUsers('usersForm')"
						>
							Sauvegarder les modifications
						</HighButtonSlot>
					</v-card-actions>
				</v-card>

				<v-card outlined>
					<v-card-title>
						<b>Utilisateurs extérieurs</b>

						<v-spacer/>
					</v-card-title>

					<v-card-subtitle class="pb-0">
						Utilisateurs extérieurs ayant actuellement un droit de lecture sur
						cette campagne.
					</v-card-subtitle>

					<ManageCampaignUsers
					:users="currentSecondaryCustomers"
					:organization="projectOrganizationId"
					@userRemoved="refreshUsers"
					/>

					<v-card-subtitle>
						Sélectionnez les utilisateurs à qui vous souhaitez donner un droit
						de lecture sur cette campagne :
					</v-card-subtitle>

					<v-form class="px-4">
						<v-autocomplete
						tabindex="18"
						label="Utilisateur(s) extérieur(s)"
						outlined
						dense
						clearable
						v-model="form.secondaryCustomers"
						:items="allSecondaryCustomers"
						item-text="name"
						item-value="id"
						prepend-inner-icon="mdi-account-search"
						multiple
						small-chips
						deletable-chips
						attach
						/>
					</v-form>

					<div class="mb-3 tw-flex tw-flex-wrap tw-justify-end tw-w-full">
						<v-card-actions>
							<v-spacer/>

							<MediumButtonSlot @click="openCreateUserModale()">
								Créer un utilisateur
							</MediumButtonSlot>
						</v-card-actions>

						<v-card-actions>
							<v-spacer/>

							<HighButtonSlot
							:_loading="loadingButton.users"
							@click="addSecondaryCustomers()"
							>
								Ajouter les utilisateurs
							</HighButtonSlot>
						</v-card-actions>
					</div>
				</v-card>
			</div>
		</div>

		<CreateEditUserModal
		@closePopin="isUserCreateModaleOpen = false"
		:organization="campaign.workspace.customerSite.organization"
		v-if="isUserCreateModaleOpen"
		@userCreated="addNewUserToData"
		:isUserEdit="false"
		/>
	</div>
</template>

<script>
import CreateEditUserModal from "@/components/entities/user/CreateEditUser.modal";
import ManageCampaignUsers from "@/components/campaign/ManageCampaignUsers.table.vue";

export default {
	name: "UpdateCampaign",
	components: {
		CreateEditUserModal,
		ManageCampaignUsers
	},
	data(){
		return {
			campaign: {
				workspace: {
					id: ""
				},
				type: ""
			},
			projectingSystems: [
				{
					name: "World Geodetic system 1984",
					val: "WGS84"
				},
				{
					name: "Lambert 93",
					val: "L93"
				},
				{
					name: "Conique conforme 42",
					val: "CC42"
				},
				{
					name: "Conique conforme 43",
					val: "CC43"
				},
				{
					name: "Conique conforme 44",
					val: "CC44"
				},
				{
					name: "Conique conforme 45",
					val: "CC45"
				},
				{
					name: "Conique conforme 46",
					val: "CC46"
				},
				{
					name: "Conique conforme 47",
					val: "CC47"
				},
				{
					name: "Conique conforme 48",
					val: "CC48"
				},
				{
					name: "Conique conforme 49",
					val: "CC49"
				},
				{
					name: "Conique conforme 50",
					val: "CC50"
				}
			],
			operatingModes: [
				{name: "M1", value: "M1"},
				{name: "M2", value: "M2"},
				{name: "M3", value: "M3"},
				{name: "M4", value: "M4"},
				{name: "M6", value: "M6"},
				{name: "M7", value: "M7"},
				{name: "M2+", value: "M2+"},
				{name: "M3+", value: "M3+"},
				{name: "Non standard", value: "not_standard"}
			],
			coringTypes: [
				{name: "Amiante", value: "CA"},
				{name: "Structurel", value: "CS"}
			],
			campaignTypes: [],
			campaignUsers: [],
			customers: [],
			engineers: [],
			studyManagers: [],
			technicians: [],
			engineer: null,
			studyManager: "",
			technician: "",
			customer: "",
			loading: true,
			allSecondaryCustomers: [],
			currentSecondaryCustomers: [],
			form: {
				name: "",
				globalComment: "",
				operatingMode: "",
				operatingModeComment: "",
				isDirMethodology: false,
				startDate: null,
				endDate: null,
				projectingSystem: null,
				nextroadRef: null,
				customerOrderRef: null,
				campaignMembers: [],
				coringType: "CA",
				predictableThickness: null,
				technicianNote: null,
				secondaryCustomers: [],
				displayNamePrefixes: [],
				newPrefix: null
			},
			loadingButton: {
				users: false,
				informations: false
			},
			userForm: {
				engineerId: this.engineer,
				studyManagerId: this.studyManager,
				technicianIds: [],
				mainCustomerId: this.customer
			},
			isUserCreateModaleOpen: false,
			menu: false,
			menu2: false,
			project: [],
			customerOrganizationId: null,
			projectOrganizationId: null,
			agencies: null,
			sites: null,
			members: null,
			diffusionCard: {
				agencyIds: [],
				siteIds: []
			},
			diffusedStudyManagers: [],
			allMembersMapped: []
		};
	},
	computed: {
		dirMethodologyLabel(){
			const labelValue = this.form.isDirMethodology === true ? "Oui" : "Non";
			return "Méthodologie DIR : " + labelValue;
		}
	},
	methods: {
		getAgenciesAndSites(){
			this.$api.agencies.findAll().then(agencies => {
				this.agencies = agencies;
			});

			this.$api.organizations
			.getAllSitesFromOrganization(this.projectOrganizationId)
			.then(sites => {
				this.sites = sites;
			});
		},
		async handleAgenciesAndSitesChange(){
			if(
				this.diffusionCard.agencyIds.length === 0 &&
        this.diffusionCard.siteIds.length === 0
			){
				this.sites = await this.$api.organizations.getAllSitesFromOrganization(
					this.projectOrganizationId
				);
				this.form.campaignMembers = [];
				this.members = this.allMembersMapped;
			}
			else if(
				this.diffusionCard.agencyIds.length > 0 &&
        this.diffusionCard.siteIds.length === 0
			){
				this.sites = await this.$api.sites.findByAgencies(
					this.diffusionCard.agencyIds
				);
				this.members = await this.$api.users.findByAgenciesAndJob(
					this.diffusionCard.agencyIds,
					"study_manager"
				);
				this.members = this.members
				.filter(user => user.id !== this.form.studyManagerId)
				.map(user => this.mapUserInformations(user, true));
				this.form.campaignMembers = [];
			}
			else if(
				this.diffusionCard.agencyIds.length === 0 &&
        this.diffusionCard.siteIds.length > 0
			){
				this.members = await this.$api.users.findBySitesAndJob(
					this.diffusionCard.siteIds,
					"study_manager"
				);
				this.members = this.members
				.filter(user => user.id !== this.form.studyManagerId)
				.map(user => this.mapUserInformations(user, true));
			}

			this.filterDiffusionInput();
		},
		async getWorkspaceInformations(){
			this.project = await this.$api.workspaces.findById(
				this.campaign.workspace.id
			);
			this.customerOrganizationId = this.project.customerSite.organization.id;
			this.projectOrganizationId = this.project.site.organization.id;

			if(this.$store.state.user.current.job !== null){
				this.getAgenciesAndSites();
			}
		},
		openCreateUserModale(){
			this.isUserCreateModaleOpen = true;
		},
		fetchCampaign(){
			this.$api.campaigns.findById(this.$route.params.id).then(campaign => {
				this.campaign = campaign;

				this.form.name = this.campaign.name;
				this.form.globalComment = this.campaign.globalComment;
				this.form.operatingMode = this.campaign.operatingMode;
				this.form.operatingModeComment = this.campaign.operatingModeComment;
				this.form.projectingSystem = this.campaign.projectingSystem;
				this.form.nextroadRef = this.campaign.nextroadRef;
				this.form.customerOrderRef = this.campaign.customerOrderRef;
				this.form.predictableThickness = this.campaign.predictableThickness;
				this.form.coringType = this.campaign.coringType;
				this.form.technicianNote = this.campaign.technicianNote;
				this.form.isDirMethodology = this.campaign.isDirMethodology;
				if(this.campaign.startDate !== null){
					this.form.startDate = new Date(this.campaign.startDate)
					.toISOString()
					.substr(0, 10);
				}
				if(this.campaign.endDate !== null){
					this.form.endDate = new Date(this.campaign.endDate)
					.toISOString()
					.substr(0, 10);
				}
				if(this.campaign.type === "coring"){
					this.getAllPrefixesFromCoringCampaign();
				}
				this.fetchCampaignUsers();
				this.fetchAllUsers();
			});
		},
		getAllPrefixesFromCoringCampaign(){
			this.$api.displayNamePrefixes
			.findByCoringCampaignId(this.$route.params.id)
			.then(response => {
				this.form.displayNamePrefixes = response;
			});
		},
		fetchCampaignUsers(){
			this.$api.campaigns
			.getUserCampaigns(this.campaign.id, true)
			.then(campaignUsers => {
				this.campaignUsers = campaignUsers;

				this.campaignUsers.forEach(campaignUser => {
					if(null !== campaignUser.as){
						if(campaignUser.customer.roles.includes("ROLE_CUSTOMER_MAIN")){
							this.customer = campaignUser.customer.id;
						}
						else if("study_manager" === campaignUser.as){
							this.studyManager = campaignUser.customer.id;
						}
						else if("engineer" === campaignUser.as){
							this.engineer = campaignUser.customer.id;
						}
						else if("technician" === campaignUser.as){
							this.userForm.technicianIds.push(campaignUser.customer.id);
						}
					}
					else {
						if(campaignUser.customer.roles.includes("ROLE_CUSTOMER_MAIN")){
							this.customer = campaignUser.customer.id;
						}
						else if("study_manager" === campaignUser.customer.job){
							this.studyManager = campaignUser.customer.id;
						}
						else if("engineer" === campaignUser.customer.job){
							this.engineer = campaignUser.customer.id;
						}
						else if("technician" === campaignUser.customer.job){
							this.userForm.technicianIds.push(campaignUser.customer.id);
						}
					}
				});
				this.userForm.engineerId = this.engineer;
				this.userForm.studyManagerId = this.studyManager;
				this.userForm.technicianId = this.technician;
				this.userForm.mainCustomerId = this.customer;
				this.loading = false;
			});
		},
		async fetchAllUsers(){
			this.getWorkspaceInformations().then(() => {
				this.$api.users
				.findByOrganization(this.projectOrganizationId)
				.then(users => {
					this.engineers = users
					.filter(user => user.job === "engineer")
					.map(user => this.mapUserInformations(user));

					this.technicians = users
					.filter(user => user.job !== "engineer")
					.map(user => this.mapUserInformations(user));

					this.studyManagers = users
					.filter(
						user =>
							user.job === "study_manager" || user.job === "commercial"
					)
					.map(user => this.mapUserInformations(user));

					this.allMembersMapped = users
					.filter(
						user =>
							user.job === "study_manager" || user.job === "commercial"
					)
					.map(user => this.mapUserInformations(user, true));

					this.members = this.allMembersMapped;

					this.members = this.members.filter(
						member => member.id !== this.$store.state.user.current.userId
					);
					this.filterDiffusionInput();
				});

				this.$api.users
				.findByOrganization(this.customerOrganizationId)
				.then(users => {

					this.customers = users.filter(user => user.roles.includes("ROLE_CUSTOMER_MAIN")).map(user => this.mapUserInformations(user));
					
					this.allSecondaryCustomers = users
					.filter(
						user =>
							user.roles.includes("ROLE_CUSTOMER_USER") &&
                  user.id !== this.customer
					)
					.map(user => this.mapUserInformations(user));
					this.filterSecondaryCustomersInput();
				});
			});

			// avec l'id de la campagne on va chercher les actuels studyManagers
			this.$api.campaigns.getDiffusedStudyManagers(this.$route.params.id).then(
				// puis pour chaque studyManager trouvé on l'ajoute à notre data this.diffusedStudyManagers
				studyManagers =>
					studyManagers.forEach(studyManager =>
						this.diffusedStudyManagers.push(studyManager.customer)
					)
			);

			// avec l'id de la campagne on va chercher les actuels utilisateurs extérieurs
			this.$api.users.findByCampaign(this.campaign.id, {readOnly: true}).then(
				// puis pour chaque utilisateur extérieur trouvé on l'ajoute à notre data this.currentSecondaryCustomers
				secondaryCustomers =>
					secondaryCustomers.forEach(secondaryCustomer =>
						this.currentSecondaryCustomers.push(secondaryCustomer)
					)
			);
		},
		mapUserInformations(user){
			return {
				name: user.firstname + " " + user.lastname + " (" + user.email + ")",
				id: user.id,
				job: user.job
			};
		},
		mapUserInformationsRole(user){
			return {
				name: user.firstname + " " + user.lastname + " (" + user.email + ")",
				id: user.id,
				job: user.role,
				readOnly: user.readOnly
			};
		},
		updateCampaignInformations(ref){
			const formRef = this.$refs[ref];
			if(formRef.validate() === true){
				this.loadingButton.informations = true;
				this.$api[this.campaign.type + "Campaigns"]
				.edit(this.campaign.id, this.form)
				.then(campaign => {
					this.campaign = campaign;
					this.loadingButton.informations = false;
				});
			}
		},
		removePrefix(prefixId){
			this.$api.displayNamePrefixes.deletePrefix(prefixId);
			this.form.displayNamePrefixes = this.form.displayNamePrefixes.filter(
				prefix => prefix.id !== prefixId
			);
		},
		addPrefix(){
			this.$api.displayNamePrefixes
			.create({
				name: this.form.newPrefix.toUpperCase(),
				coringCampaignId: this.$route.params.id
			})
			.then(response => {
				this.form.displayNamePrefixes.push(response);
				this.form.newPrefix = null;
			});
		},
		updateCampaignUsers(ref){
			const formRef = this.$refs[ref];
			if(formRef.validate() === true){
				this.loadingButton.users = true;
				this.$api.campaigns
				.editUsers(this.campaign.id, this.userForm)
				.then(() => {
					this.loadingButton.users = false;
				});
			}
		},
		updateDiffusionMembers(ref){
			const formRef = this.$refs[ref];
			if(formRef.validate() === true){
				// pour chaque membre du formulaire de diffusion,
				this.form.campaignMembers.forEach(member =>
				// les ajouter en membre de la campagne courante avec readOnly et isTeamMember à false
					this.$api.campaigns
					.addMember(this.campaign.id, member, {
						isTeamMember: false,
						readOnly: false
					})
					.then(studyManager => {
						this.diffusedStudyManagers.push({
							...studyManager.customer,
							site: studyManager.campaign.workspace.site
						});
						this.filterDiffusionInput();
						this.form.campaignMembers = [];
					})
				);
			}
		},
		addSecondaryCustomers(){
			this.form.secondaryCustomers.forEach(customer =>
				this.$api.campaigns
				.addMember(this.campaign.id, customer, {
					isTeamMember: false,
					readOnly: true
				})
				.then(customer => {
					customer.customer.site = customer.campaign.workspace.customerSite;
					this.currentSecondaryCustomers.push(customer.customer);
					this.form.secondaryCustomers = [];
					this.filterSecondaryCustomersInput();
				})
			);
		},
		filterDiffusionInput(){
			// dans la liste des potentiels study manager de la diffusion
			// on ne doit ni avoir le study manager référent
			const studyManagerId = this.campaignUsers.find(
				user => user.customer.job === "study_manager"
			).customer.id;
			// ni l'utilisateur actuellement connecté
			const connectedUserId = this.$store.state.user.current.userId;
			// ni les study manager ayant déjà été ajoutés à la diffusion
			const diffusedStudyManagersIds = this.diffusedStudyManagers.map(
				studyManager => studyManager.id
			);

			this.members = this.members.filter(
				member =>
					member.id != studyManagerId &&
          member.id != connectedUserId &&
          false === diffusedStudyManagersIds.includes(member.id)
			);
		},
		filterSecondaryCustomersInput(){
			// dans la liste des potentiels utilisateurs extérieurs
			// on ne doit pas avoir les utilisateurs ayant déjà été ajoutés à la campagne
			this.allSecondaryCustomers = this.allSecondaryCustomers.filter(
				user =>
					undefined ===
          this.currentSecondaryCustomers.find(
          	customer => customer.id === user.id
          )
			);
		},
		addNewUserToData(user){
			this.allSecondaryCustomers.push(this.mapUserInformations(user));
		},
		refreshUsers(user){
			if(user.roles.includes("ROLE_CUSTOMER_USER") === true){
				// si l'utilisateur a le role customer il vient du tableau des utilisateurs extérieurs
				// il faut donc mettre à jour la liste des utilisateurs extérieurs
				this.currentSecondaryCustomers = this.currentSecondaryCustomers.filter(
					customer => customer.id != user.id
				);
				this.allSecondaryCustomers.push(this.mapUserInformations(user));
			}
			else {
				// sinon c'est qu'il vient du tableau des chargés d'affaires de diffusion
				this.diffusedStudyManagers = this.diffusedStudyManagers.filter(
					studyManager => studyManager.id != user.id
				);
				this.members.push(this.mapUserInformations(user));
			}
		}
	},
	mounted(){
		this.fetchCampaign();
	}
};
</script>

<style lang="scss" scoped>
#campaign {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .diffusion-subtitle {
    margin-bottom: 0;
  }

  .m_subtitle {
    color: grey;
    font-family: "Open Sans", sans-serif;
  }

  .methodology {
    position: relative;
    bottom: 12px;
  }

  .methodology ::v-deep .v-input__slot {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
</style>

<style lang="scss">
// I needed to move the input selector in another "stylesheet",
// because the scoped one do not allow me to make "text-transform: uppercase" work.
// So I moved all the selectors for display name prefixes chips in this "stylesheet".
.prefix-chip {
  margin: 4px;
}

.new-prefix {
  display: flex;
  justify-content: space-between;
}

.new-prefix-input {
  margin-right: 16px !important;
}

.new-prefix-input input {
  text-transform: uppercase !important;
}
</style>
