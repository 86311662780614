<template>
	<div
	v-if="showCore === true"
	@click="clicked"
	:style="{'--bg-color': color}"
	:class="{'select': core.id === selectedCore.id}"
	coring-map-marker
	>
		{{ core.number }}
	</div>
</template>

<script>
import Vue from "vue";
import {mapGetters, mapActions} from "vuex";
import mapboxgl from "mapbox-gl";
import CoringMacroPopup from "./CoringMacroPopup.vue";

export default Vue.extend({
	name: "CoringMapMarker",
	components: {
		
	},
	props: {
		core: {
			default: false
		},
	},
	data(){
		return {
			clickCount: 0,
			popup: false
		};
	},
	computed: {
		...mapGetters("coringMacro", [
			"map", "filter", "filterValue", "selectedCore", "filterCampaign", "selectedOrganization"
		]),

		color(){
			if(!this.filter) return "green";
			else if(this.filter === "PAH"){
				if(this.core.sumPAH >= 1000) return "black";
				else if(this.core.sumPAH >= 500 && this.core.sumPAH < 1000) return "red";
				else if(this.core.sumPAH >= 50 && this.core.sumPAH < 500) return "orange";
				else if(this.core.sumPAH < 50) return "green";
			}
			else if(this.filter === "asbestos"){
				if(this.core.containsAsbestos === true) return "red";
				else return "green";
			}
			else if(this.filter === "TH"){
				if(this.core.sumTH >= 500) return "orange";
				else if(this.core.sumTH < 500) return "green";
			}
		},

		showCore(){
			if(this.filterCampaign.indexOf(this.core.campaignId) === -1 || this.core.organizationId !== this.selectedOrganization) return false;
			else if(!this.filter) return true;
			else if(this.filter === "PAH"){
				if(this.core.sumPAH >= 1000 && this.filterValue.PAH.indexOf(1000) === -1) return false;
				else if(this.core.sumPAH >= 500 && this.core.sumPAH < 1000 && this.filterValue.PAH.indexOf(500) === -1) return false;
				else if(this.core.sumPAH >= 50 && this.core.sumPAH < 500 && this.filterValue.PAH.indexOf(50) === -1) return false;
				else if(this.core.sumPAH < 50 && this.filterValue.PAH.indexOf(0) === -1) return false;
				else return true;
			}
			else if(this.filter === "asbestos"){
				if(this.core.containsAsbestos === true && this.filterValue.asbestos.indexOf(true) === -1) return false;
				else if(this.core.containsAsbestos === false && this.filterValue.asbestos.indexOf(false) === -1) return false;
				else return true;
			}
			else if(this.filter === "TH"){
				if(this.core.sumTH >= 500 && this.filterValue.TH.indexOf(500) === -1) return false;
				else if(this.core.sumTH < 500 && this.filterValue.TH.indexOf(0) === -1) return false;
				else return true;
			}

		}
	},
	watch: {
		selectedCore(){
			if((this.selectedCore === false || this.selectedCore.id !== this.core.id) && this.popup !== false) this.popup.$destroy();
		}
	},
	methods: {
		...mapActions("coringMacro", ["selectCore"]),

		clicked(){
			this.selectCore(this.core);
			this.displayPopup();
		},


		async displayPopup(){
			let popup = new mapboxgl.Popup({offset: 25, closeButton: false, closeOnClick: false})
			.setHTML("<div id=\"vue-popup-content\"></div>")
			.setLngLat({lat: this.core.lat, lng: this.core.lng})
			.addTo(this.map);



			this.popup = new CoringMacroPopup({
				propsData: {
					core: await this.$api.cores.get(this.core.id),
					popup: popup,
				},
				parent: this,
			})
			.$mount("#vue-popup-content");
		},
	},
});
</script>

<style lang="scss">
div[coring-map-marker]{
    color: white;
    background: var(--bg-color);
    aspect-ratio: 1/1;
    height: 20px;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    cursor: pointer;

    &.select{
        border: solid black 2px;
    }
}
</style>
