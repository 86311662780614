export default {
  hasAsbestos(prestations = []) {
    if (0 === prestations.length) return false;
    let hasAsbestos = false;

    prestations.forEach(prestation => {
      if (prestation.type === "ASBESTOS") {
        hasAsbestos = true;
      }
    });
    return hasAsbestos;
  },
  hasPAH(prestations = []) {
    if (0 === prestations.length) return false;
    let hasPAH = false;

    prestations.forEach(prestation => {
      if (prestation.type === "PAH") {
        hasPAH = true;
      }
    });
    return hasPAH;
  },
  hasTH(prestations = []) {
    if (0 === prestations.length) return false;
    let hasTH = false;

    prestations.forEach(prestation => {
      if (prestation.type === "TH") {
        hasTH = true;
      }
    });
    return hasTH;
  }
};
