<template>
	<v-dialog
	v-model="dialog"
	persistent
	max-width="750"
	class="popupQI__allBox"
	>
		<template v-slot:activator="{ on, attrs }">
			<ButtonSlot
			v-bind="attrs"
			v-on="on"
			:class="_class"
			_icon="mdi-account-hard-hat"
			:_res="_res"
			_theme="light-gray"
			>
				Contacter un ingénieur
			</ButtonSlot>
		</template>

		<section>
			<v-card class="popupQI__container">
				<h3 class="popupQI__titleAtr">
					Contacter un ingénieur
				</h3>

				<v-card-text>
					<div class="popupQI__text">
						Pour toute question liée à votre campagne, un ingénieur chaussées vous
						répondra dans les meilleurs délais. Pour contacter le support
						informatique, merci de bien fermer cette fenêtre et cliquer sur
						"contacter le support" en bas à gauche de votre écran.
					</div>
				</v-card-text>

				<v-spacer/>

				<v-form method="post">
					<v-text-field
					label="Sujet"
					outlined
					dense
					clearable
					type="text"
					id="subject"
					placeholder="Sujet"
					v-model="form.subject"
					:rules="[$rules.max500, $rules.required]"
					counter="500"
					:validate-on-blur="true"
					/>

					<v-textarea
					label="Message"
					outlined
					dense
					clearable
					type="text"
					id="message"
					placeholder="Message"
					v-model="form.message"
					rows="3"
					no-resize
					:rules="[$rules.max1000, $rules.required]"
					counter="1000"
					:validate-on-blur="true"
					/>

					<v-file-input
					label="Joindre une capture"
					outlined
					dense
					type="file"
					accept=".jpg, .jpeg, .png"
					v-model="screenshot"
					/>

					<v-img :src="urlScreenshot"/>
				</v-form>

				<v-spacer/>

				<v-card-actions>
					<LowButtonSlot @click="dialog = false + clear()">
						Annuler
					</LowButtonSlot>

					<div class="popupQI__buttonSubmit">
						<ButtonSlot
						@click="sendMailToEngineer()"
						:_disabled="!form.message"
						>
							Envoyer
						</ButtonSlot>
					</div>
				</v-card-actions>
			</v-card>
		</section>
	</v-dialog>
</template>

<script>
export default {
	name: "EngineerQuestion",

	props: {
		"_class": String,
		"_res": {
			type: Number,
			default: 0,
			require: false,
		}
	},

	data(){
		return {
			dialog: false,
			form: {message: "", subject: ""},
			output: null,
			screenshot: null,
			urlScreenshot: null
		};
	},
	methods: {
		async sendMailToEngineer(){
			let formData = new FormData();
			formData.append("message", this.form.message);
			formData.append("subject", this.form.subject);
			formData.append("screenshot", this.screenshot);
			await this.$api.coringCampaigns.sendMailToEngineer(
				this.$route.params.id,
				formData
			);
		},

		/*async print() {
        const el = this.$refs.printMe;
        const options = {
          type: 'dataURL',
          allowTaint : false,
          useCORS: true,
          imageTimeout: 0,
        };
        const token = this.accessToken;
        this.output = await this.$html2canvas(el , options , token);
    }, */

		clear(){
			(this.form = {message: "", subject: ""}), (this.screenshot = null);
		}

		/*  clearScreen () {
        this.output= null
    },*/
	}
};
</script>

<style lang="scss" scoped>
.popupQI__container {
  text-align: center;
  padding: 20px 20px;

  & > h3 {
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.15px;
  }
}

.popupQI__titleAtr {
  margin: 10px;
  text-align: center;
}

.popupQI__text {
  color: #888888;
}

.v-text-field {
  margin-left: 35px;
  margin-right: 35px;
}

.popupQI__containerForm {
  max-height: 350px;
}

.input__file {
  margin-top: 5px;
}

.popupQI__TextIcon {
  font-weight: bold;
  padding: 10px;
  margin-top: 0;
}

.popupQI__buttonSubmit {
  margin-left: 68%;
}

.popupQI__btnScreen {
  margin: 5px;
  padding: 20px;
  width: 18%;
}
</style>
