var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{staticClass:"bordered",attrs:{"loading":_vm.users === [],"headers":_vm.userHeaders,"items":_vm.users,"items-per-page":5,"search":_vm.userSearch,"hide-default-footer":_vm.users.length < 6},scopedSlots:_vm._u([{key:"item.firstname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#b80000"},on:{"click":function($event){return _vm.removeUserFromCampaign(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-account-minus")])]}}],null,true)},[_c('span',[_vm._v("Retirer l'utilisateur de la campagne")])])],1)]}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }