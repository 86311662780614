<template>
	<div class="tw-w-[200px] tw-flex tw-flex-col tw-gap-[10px] tw-max-h-[500px]">
		<div class="tw-w-full tw-flex tw-flex-col">
			<h3>
				{{
					core.displayName !== null && code.displayNamePrefix !== null ?
						code.displayNamePrefix.name + core.displayName :
						"Numéro : " + core.number
				}}
			</h3>

			<div class="tw-w-full tw-flex tw-flex-col">
				<!--<tr
				v-if="extractedDate"
				class="tw-w-full tw-flex tw-justify-between"
				>
					<td><span>Date du prélèvement:</span></td>

					<td>
						<span>{{ extractedDate.createdAt.split("T")[0].split("-").reverse().join("/") }}</span>
					</td>
				</tr>

				<tr
				v-if="cancelledDate"
				class="tw-w-full tw-flex tw-justify-between"
				>
					<td><span>Date de l'annulation:</span></td>

					<td>
						<span>{{ cancelledDate.createdAt.split("T")[0].split("-").reverse().join("/") }}</span>
					</td>
				</tr>-->

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Route:</span></td>

					<td>
						<span>{{ core.road.name }}</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Sens:</span></td>

					<td>
						<span>{{ core.direction }}</span>
					</td>
				</tr>

				<tr
				class="tw-w-full tw-flex tw-justify-between"
				v-if="core.landmark && core.abscissa"
				>
					<td><span>PR + ABS : </span></td>

					<td>
						<span>
							{{ core.landmark ? core.landmark : "" }}
							{{
								core.landmark && core.abscissa
									? "+" + core.abscissa
									: ""
							}}
						</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Epaisseur : </span></td>

					<td>
						<span> {{ depth }} cm</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Structure : </span></td>

					<td>
						<span> {{ core.structureType }}</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Structure complète : </span></td>

					<td>
						<span> {{ core.isFullStructure ? "Oui" : "Non" }}</span>
					</td>
				</tr>
			</div>
			
			<div class="tw-w-full tw-h-[1px] tw-bg-[rgba(68,68,68,0.562)]"/>

			<h3>
				Matériaux
			</h3>

			<table class="tw-w-full tw-flex tw-flex-col">
				<tr
				class="tw-w-full tw-flex tw-justify-between"
				v-if="layers !== false"
				v-for="layer in layers"
				:key="layer.id"
				>
					<td>
						<span>Couche {{ layer.number }}</span>
					</td>

					<td>
						<span>{{ layer.materialCategory.name }}
							{{ layer.material? " - " + layer.material.name : "" }}</span>
					</td>
				</tr>

				<tr
				class="tw-w-full tw-flex tw-justify-between"
				v-if="core.status !== 'results_acquired'"
				>
					<td><span>Etat:</span></td>

					<td>
						<span>{{ $t(core.status) }}</span>
					</td>
				</tr>
			</table>
		</div>

		<div class="tw-flex tw-flex-col tw-items-center tw-gap-[10px]">
			<ButtonSlot
			_theme="light-gray"
			@click="$router.push('/coring-campaign/' + core.coringCampaign.id+'/dashboard')"
			_small
			>
				campagne
			</ButtonSlot>

			<ButtonSlot
			@click="downloadPDF"
			_small
			>
				Rapport PDF
			</ButtonSlot>

			<ButtonSlot
			_theme="light-gray"
			@click="unSelectCore"
			_small
			>
				fermer
			</ButtonSlot>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import {mapActions, mapGetters, mapMutations,} from "vuex";

export default Vue.extend({
	props: {
		core: {
			
		},
		popup: {

		}
	},
	data(){
		return {
			layers: this.core.layersAndInterfaces.filter(value => value.type === "layer")
		};
	},
	computed: {
		depth(){
			return this.layers.reduce((v, l) => v += l.thickness, 0);
		},
		/*extractedDate(){
			return this.core.statusChange.find(v => v.status === "extracted");
		},
		cancelledDate(){
			return this.core.statusChange.find(v => v.status === "cancelled");
		}*/

	},
	methods: {
		...mapMutations("coringMacro", []),
		...mapActions("coringMacro", ["unSelectCore"]),

		async downloadPDF(){
			const blob = await this.$api.cores.getPdf(this.core.id);
			const fileName =
        	"core-" + this.core.fullDisplayNameAsString
        		? this.core.fullDisplayNameAsString
        		: this.core.businessId;
			
			var newBlob = new Blob([blob], {type: "application/pdf"});
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");
			link.href = data;
			link.download = fileName;
			window.open(link, "_blank");
		},
		close(){
			this.$destroy();
			this.popup.remove();
		}
	},
	mounted(){
		
	},
	destroyed(){
		this.popup.remove();
	}
});
</script>

<style lang="scss">

</style>
