<template>
    <v-card flat>
      <v-card-text>
        <v-data-table
          :loading="users === []"
          :headers="userHeaders"
          :items="users"
          :items-per-page="5"
          :search="userSearch"
          class="bordered"
          v-model="selectedUsers"
          :hide-default-footer="users.length < 6"
        >
          <template v-slot:item.firstname="{ item }">
            {{ item.firstname }} {{ item.lastname }}
          </template>
          <template v-slot:item.edit="{ item }">
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="removeUserFromCampaign(item)"
                    color="#b80000"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-account-minus</v-icon
                  >
                </template>
                <span>Retirer l'utilisateur de la campagne</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  export default {
    props: ["users", "organization"],
    data() {
      return {
        userSearch: "",
        selectedUsers: [],
        userHeaders: [
          { text: "Nom complet", value: "firstname" },
          { text: "Email", value: "email" },
          { text: "Site", value: "site.name" },
          { text: "Retirer", value: "edit", align: "center" }
        ]
      };
    },
    methods: {
      removeUserFromCampaign(user) {
        this.$api.campaigns.removeMember(this.$route.params.id, user.id).then(() => this.$emit("userRemoved", user));
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .bordered {
    border: thin solid rgba(0, 0, 0, 0.12);
  }
  </style>
  