<template>
	<div class="tw-w-[200px] tw-p-[10px] tw-flex tw-flex-col tw-gap-[10px]">
		<div class="tw-w-full tw-flex tw-flex-col">
			<h3>
				{{
					coreData.displayName !== null && coreData.displayNamePrefix !== null ?
						coreData.displayNamePrefix.name + coreData.displayName :
						coreData.businessId
				}}
			</h3>

			<div class="tw-w-full tw-flex tw-flex-col">
				<tr
				v-if="extractedDate"
				class="tw-w-full tw-flex tw-justify-between"
				>
					<td><span>Date du prélèvement:</span></td>

					<td>
						<span>{{ extractedDate.createdAt.split("T")[0].split("-").reverse().join("/") }}</span>
					</td>
				</tr>

				<tr
				v-if="cancelledDate"
				class="tw-w-full tw-flex tw-justify-between"
				>
					<td><span>Date de l'annulation:</span></td>

					<td>
						<span>{{ cancelledDate.createdAt.split("T")[0].split("-").reverse().join("/") }}</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Route:</span></td>

					<td>
						<span>{{ coreData.road.name }}</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Sens:</span></td>

					<td>
						<span>{{ coreData.direction }}</span>
					</td>
				</tr>

				<tr
				class="tw-w-full tw-flex tw-justify-between"
				v-if="coreData.landmark && coreData.abscissa"
				>
					<td><span>PR + ABS : </span></td>

					<td>
						<span>
							{{ coreData.landmark ? coreData.landmark : "" }}
							{{
								coreData.landmark && coreData.abscissa
									? "+" + coreData.abscissa
									: ""
							}}</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Epaisseur : </span></td>

					<td>
						<span> {{ depth }} cm</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Type de structure : </span></td>

					<td>
						<span> {{ coreData.structureType }}</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Structure complète : </span></td>

					<td>
						<span> {{ coreData.isFullStructure ? "Oui" : "Non" }}</span>
					</td>
				</tr>
			</div>
			
			<div class="tw-w-full tw-h-[1px] tw-bg-[rgba(68,68,68,0.562)]"/>

			<h3>
				Matériaux
			</h3>

			<table class="tw-w-full tw-flex tw-flex-col">
				<tr
				class="tw-w-full tw-flex tw-justify-between"
				v-if="layers !== false"
				v-for="layer in layers"
				:key="layer.id"
				>
					<td>
						<span>Couche {{ layer.number }}</span>
					</td>

					<td>
						<span>{{ layer.materialCategory.name }}
							{{ layer.material? " - " + layer.material.name : "" }}</span>
					</td>
				</tr>

				<tr
				class="tw-w-full tw-flex tw-justify-between"
				v-if="coreData.status !== 'results_acquired'"
				>
					<td><span>Etat:</span></td>

					<td>
						<span>{{ fr[coreData.status] }}</span>
					</td>
				</tr>
			</table>
		</div>

		<div class="tw-flex tw-flex-col tw-items-center tw-gap-[10px]">
			<ButtonSlot
			class="MapPopupBtn"
			@click="downloadPDF"
			>
				Rapport PDF
			</ButtonSlot>

			<ButtonSlot
			_theme="light-gray"
			@click="STOP_EDITING_CORE"
			>
				fermer
			</ButtonSlot>
		</div>

		<!-- <div class="center">
			<v-tooltip
			top
			v-for="(img, index) in images"
			:key="index"
			>
				<template v-slot:activator="{ on, attrs }">
					<img
					v-on="on"
					v-bind="attrs"
					class="campaign-form__imgBox"
					target="_blank"
					:src="img"
					:style="{
						backgroundImage: `url(${img})`
					}"
					@click="
						isTogglePoppinPicture = true;
						image = img;
					"
					/>
				</template>

				<span>Cliquez pour voir <br/>l'aperçu de votre image</span>
			</v-tooltip>
		</div> -->
	</div>
</template>

<script>
import Vue from "vue";
import fr from "@/locales/fr";
import {mapGetters, mapMutations} from "vuex";

export default Vue.extend({
	props: {
		coreData: {
			required: true,
			type: Object
		},
		popup: {

		}
	},
	data(){
		return {
			fr: fr,
			hap: 100,
			hct: 501,
			amiante: 0.2,
			images: [],
			isTogglePoppinPicture: false,
			layers: false
		};
	},
	computed: {
		...mapGetters("coring", ["editingCore"]),

		depth(){
			let depth = 0;
			this.coreData.layersAndInterfaces.forEach(layer => {
				if(layer.type === "layer")depth += layer.thickness;
			});

			return depth;
		},
		extractedDate(){
			return this.coreData.statusChange.find(v => v.status === "extracted");
		},
		cancelledDate(){
			return this.coreData.statusChange.find(v => v.status === "cancelled");
		}
	},
	watch: {
		editingCore(){
			if(this.editingCore === false || this.editingCore.number !== this.coreData.number) this.close();
		}
	},
	methods: {
		...mapMutations("coring", ["STOP_EDITING_CORE"]),

		async downloadPDF(){
			const pdf = await this.$api.cores.getPdf(this.coreData.id);
			const fileName =
        "core-" + this.coreData.fullDisplayNameAsString
        	? this.coreData.fullDisplayNameAsString
        	: this.coreData.businessId;
			this.downloadFile(pdf, fileName);
		},
		downloadFile(blob, fileName){
			var newBlob = new Blob([blob], {type: "application/pdf"});
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");
			link.href = data;
			link.download = fileName;
			window.open(link, "_blank");
		},
		close(){
			this.$destroy();
			this.popup.remove();
		}
	},
	mounted(){
		this.layers = this.coreData.layersAndInterfaces.filter(value => value.type === "layer");
	}
});
</script>

<style lang="scss">
.table > {
	display: flex;
	justify-content: space-between;
}

.mapboxgl-popup {
  max-width: 300px !important;
}
.mapboxgl-popup-content {
  background: #ffffff;
  color: #000;
  border-radius: 10px;
  top: 0px;

  .MapPopup {
    font: 12px/20px Helvetica Neue, Arial, Helvetica, sans-serif;

	width: 200px;
	max-height: 400px;
  	overflow-y: auto;

    h3 {
      margin-bottom: 10px;

      margin-left: 1px;

      &.separator {
        font-size: 13px !important;
        padding-top: 5px;
        margin-top: 5px;
        border-top: 1px solid rgba(68, 68, 68, 0.562);
        width: 100%;
      }
    }

    &__goodScore {
      p {
        // margin: auto;
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        // margin-top: 10px;
        margin-bottom: 0px;
      }
      span:nth-child(1) {
        text-transform: uppercase;
        padding: 5px;
        border-radius: 20px;
        background-color: #23c015;
        color: white;
      }
    }
    &__mediumScore {
      p {
        // margin: auto;
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        // margin-top: 10px;
        margin-bottom: 0px;
      }
      span:nth-child(1) {
        padding: 5px;
        border-radius: 20px;
        background-color: #e4ad35;
        color: white;
      }
    }
    &__badScore {
      p {
        // margin: auto;
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        // margin-top: 10px;
        margin-bottom: 0px;
      }
      span:nth-child(1) {
        padding: 5px;
        border-radius: 20px;
        background-color: #e43d35;
        color: white;
      }
    }
    &__veryBadScore {
      p {
        // margin: auto;
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        // margin-top: 10px;
        margin-bottom: 0px;
      }
      span:nth-child(1) {
        padding: 5px;
        border-radius: 20px;
        background-color: #000000;
        color: white;
      }
    }
    &__otherBox {
      border-bottom: 1px solid rgba(68, 68, 68, 0.562);
      p {
        margin: auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        padding-left: 20px;
        padding-right: 20px;
      }
      span:nth-child(1) {
        border-radius: 20px;
      }
    }
    &__etatBox {
      p {
        margin: auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        padding-left: 20px;
        padding-right: 20px;
      }
      span:nth-child(1) {
        text-transform: uppercase;
        border-radius: 20px;
      }
    }
    &__itemBox {
      padding-top: 10px;
      p {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
      }
    }
    &__btnBox {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }
    &__pannelHeader {
      margin: auto;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
    }
  }
  .center {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
</style>
