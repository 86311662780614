<template>
	<div 
	class="tw-overflow-hidden tw-relative"
	coring-map
	>
		<Map
		class="tw-w-full tw-h-full" 
		ref="map"
		@load="map=>SET_MAP(map)"
		/>

		<slot/>
	</div>
</template>

<script>
import Map from "../../map/Map.vue";
import mapboxgl from "mapbox-gl";
import CoringMacroMarker from "./CoringMacroMarker.vue";
import {mapGetters, mapActions, mapMutations} from "vuex";

export default {
	name: "CoringMacroMap",
	components: {
		Map,
	},
	props: {

	},
	data(){
		return {
			markers: [],
		};
	},
	computed: {
		...mapGetters("coringMacro", ["cores", "map"]),
	},
	watch: {
		cores(n, o){
			if(o === false && this.cores[0]){
				let {maxLng, minLng} = this.cores.reduce(
					(a, core) => {
						if(core.lng > a.maxLng) a.maxLng = core.lng;
						if(core.lng < a.minLng) a.minLng = core.lng;
						return a;
					}, 
					{maxLng: 0, minLng: Infinity}
				);

				let {maxLat, minLat} = this.cores.reduce(
					(a, core) => {
						if(core.lat > a.maxLat) a.maxLat = core.lat;
						if(core.lat < a.minLat) a.minLat = core.lat;
						return a;
					}, 
					{maxLat: 0, minLat: Infinity}
				);
				
				let lng = (maxLng + minLng) / 2;
				let lat = (maxLat + minLat) / 2;

				this.$refs.map.center = {lng, lat};
				this.$refs.map.zoom = 15;

				this.$refs.map.bounds = {
					maxLat, minLat, maxLng, minLng
				};
			}
			if(this.cores !== false){
				this.removeCores();
				this.displayCores();
			}
		},
		isAddingCore(){
			if(this.isAddingCore === true){
				this.addingCore();
			}
			else {
				this.unAddingCore();
			}
		}
	},
	methods: {
		...mapMutations("coringMacro", ["SET_MAP"]),

		displayCores(){
			for(const core of this.cores){
				this.createMaker(core);
			}
		},

		createMaker(core){
			if(core.lng === undefined || core.lat === undefined) return;
			let div = document.createElement("div");
			div.innerHTML = `<div id="map-${this.$refs.map.id}-marker-${core.id}"></div>`;
			let el = new CoringMacroMarker({
				propsData: {
					core: core,
				},
				parent: this,
			});

			this.markers.push({
				vue: el,
				map: new mapboxgl.Marker(div).setLngLat({lng: core.lng, lat: core.lat}).addTo(this.$refs.map.map),
				destroy(){
					this.vue.$destroy();
					this.map.remove();
				},
			});

			el.$mount("#" + div.children[0].id);
		},

		removeCores(){
			for(const marker of this.markers){
				marker.destroy();
			}
		},


	},
	created(){

	},
	destroyed(){

	}
};
</script>

<style lang="scss">
div[coring-map]{
    
}
</style>
