<template>
	<div
	class="tw-absolute tw-top-[10px] tw-left-[10px] tw-w-[300px] tw-bg-[white] tw-p-[10px] tw-rounded-[4px] tw-flex tw-flex-col tw-gap-[10px]"
	>
		<h3 v-show="(organizations || []).length > 1">
			Organisations
		</h3>

		<div
		v-show="(organizations || []).length > 1"
		class="tw-ml-[10px]"
		>
			<VSelect
			:items="organizations || []"
			item-text="name"
			item-value="id"
			v-model="selectedOrga"
			outline
			hide-details
			dense
			outlined
			/>
		</div>

		<h3>Liste des couches</h3>

		<div class="tw-ml-[10px]">
			<v-radio-group 
			v-model="selected"
			>
				<v-radio
				v-for="item of items"
				v-if="!item.disabled"
				color="darkgrey"
				class="tw-m-0 tw-text-[12px] tw-p-0"
				:label="item.name"
				:value="item.id"
				danse
				hide-details
				:false-value="false"
				/>
			</v-radio-group>
		</div>

		<!-- <div v-if="false">
			<div v-for="project of projects">
				<VCheckbox
				color="darkgrey"
				class="tw-m-0 tw-p-0"
				:label="project.name"
				danse
				hide-details
				:false-value="false"
				/>

				<div class="tw-ml-[10px]">
					<VCheckbox
					v-for="campaign of campaigns.filter(v => v.projectId === project.id)"
					color="darkgrey"
					class="tw-m-0 tw-p-0"
					:label="campaign.name"
					:value="campaign.id"
					v-model="selectedCampaigns"
					danse
					hide-details
					:false-value="false"
					>
						<template v-slot:label>
							<span class="tw-overflow-hidden tw-text-ellipsi">
								{{ campaign.name }}
							</span>
						</template>
					</VCheckbox>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
	data(){
		return {
			items: [],
		};
	},
	computed: {
		...mapGetters("coringMacro", [
			"projects", "campaigns", "filterCampaign", "cores", "filter", "organizations", "selectedOrganization"
		]),

		selectedCampaigns: {
			get(){
				return this.filterCampaign;
			},
			set(value){
				this.SET_FIlTER_CAMPAIGN(value);
			}
		},

		selected: {
			set(arg){
				this.SET_FIlTER(arg);
			},
			get(){
				return this.filter;
			}
		},

		selectedOrga: {
			get(){
				return this.selectedOrganization; 
			},
			set(value){
				this.selectOrganization(value);
			}
		}
	},
	watch: {
		cores(n, o){
			if(o === false && n[0] !== undefined){
				this.setlayerList();
			}
		}
	},
	methods: {
		...mapMutations("coringMacro", ["SET_FIlTER_CAMPAIGN", "SET_FIlTER"]),
		...mapActions("coringMacro", ["selectOrganization"]),

		setlayerList(){
			let coreResultType = {
				asbestos: false,
				PAH: false,
				TH: false,
			};

			for(const core of this.cores){
				if(core.sumPAH !== null) coreResultType.PAH = true;
				if(core.sumTH !== null) coreResultType.TH = true;
				if(core.containsAsbestos !== null) coreResultType.asbestos = true;
			}
			
			let items = [];
			for(const [key, value] of Object.entries(coreResultType)){
				items.push({
					name: this.$t(key),
					disabled: !value,
					id: key,
				});
			}
			this.items = items;
		}
	}
};
</script>

<style>
.v-input--selection-controls {
	margin-top: 0px !important;
}
.v-input--radio-group--column .v-input--radio-group__input {
	gap: 5px !important;
}
</style>
