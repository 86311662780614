<template>
	<div class="tw-h-full tw-flex tw-flex-col">
		<SendCoringCampaignToProviderValidationPopin
		v-if="campaign.status === 'taking_validated'"
		:site="campaign.workspace.site"
		v-show="isCoringCampaignSendDataPopinDisplay"
		@closePopin="isCoringCampaignSendDataPopinDisplay = false"
		:campaign="campaign"
		:needButton="false"
		/>

		<HeaderSlot
		title="Paramétrer les prestations"
		class="tw-mb-[10px] tw-pt-[24px] tw-px-[24px]"
		@clickBackButton="$router.push('/coring-campaign/' + campaign.id)"
		>
			<MediumButtonSlot @click="removeAll()">
				Supprimer tout
			</MediumButtonSlot>

			<ButtonSlot
			@click="allLayerHasPrestation() === true ? saveChanges() : displayModal = true;"
			:_disabled="isValidable === false"
			>
				Valider les prestations
			</ButtonSlot>

			<ButtonSlot
			@click="isCoringCampaignSendDataPopinDisplay = true"
			:_disabled="isValidated === false"
			>
				Envoyer au laboratoire
			</ButtonSlot>
		</HeaderSlot>

		<DragRow
		@isDragging="map.resize()"
		@dragging="map.resize()"
		>
			<template #top>
				<div class="cores-container">
					<CorePrestation
					v-for="(core, index) in displayedCores"
					:key="core.id"
					v-model="cores[index + itemsPerPage * (page - 1)]"
					:prestations="selectedPrestations"
					:provider="selectedProvider[0]"
					:layerCoef="layerCoef"
					@focusOnCore="createMarkers"
					@changePage="changePage"
					:layerPrestations="tempPrestations"
					/>
				</div>

				<v-pagination
				color="#290703"
				class="mb-4 pagination"
				v-if="pageCount > 1"
				v-model="page"
				:length="pageCount"
				/>
			</template>

			<template #bottom>
				<DragCol
				@isDragging="map.resize()"
				@dragging="map.resize()"
				>
					<template
					#left
					class="height100"
					>
						<div class="tw-flex tw-justify-between pa-4">
							<div class="pl-2">
								<h1>Prestations</h1>
							</div>

							<div
							v-if="step == 2"
							class="tw-flex tw-flex-row tw-gap-[5px]"
							>
								<MediumButtonSlot
								@click="
									step = 1;
									selectedPrestations = [];
								"
								>
									Retour au prestataire
								</MediumButtonSlot>

								<ButtonSlot
								:_popup="{
									title: 'Confirmer l\'action',
									subTitle:
										'Êtes-vous sûr de vouloir appliquer les prestations sélectionnées aux couches des carottes visibles à l\'écran ?'
								}"
								_tooltip="Appliquer les prestations sélectionnées aux couches des carottes visibles à l'écran"
								:_disabled="applyPrestationsDisabled"
								@click="applyPrestationsToAllLayers()"
								>
									Appliquer à toutes les couches à l'écran
								</ButtonSlot>
							</div>
						</div>

						<div class="left-bottom-container">
							<div class="prestations-container">
								<v-stepper
								elevation="0"
								v-model="step"
								>
									<v-stepper-items>
										<v-stepper-content step="1">
											<v-container fluid>
												<v-data-table
												hide-default-footer
												v-model="selectedProvider"
												:items="providers"
												:headers="providerHeaders"
												:singleSelect="true"
												show-select
												item-key="id"
												class="outlined"
												:loading="providers === []"
												:footer-props="{
													'disable-items-per-page': true
												}"
												@click:row="(item, row) => row.select(true)"
												disable-pagination
												>
													<template
													v-slot:item.data-table-select="{
														isSelected,
														select
													}"
													>
														<v-simple-checkbox
														:ripple="false"
														:value="isSelected"
														@input="select($event)"
														off-icon="mdi-radiobox-blank"
														on-icon="mdi-radiobox-marked"
														color="black"
														/>
													</template>
												</v-data-table>
											</v-container>

											<v-card-actions>
												<v-spacer/>

												<HighButtonSlot
												:_disabled="null === selectedProvider[0]"
												class="right_btn"
												@click="step = 2"
												>
													Continuer
												</HighButtonSlot>
											</v-card-actions>
										</v-stepper-content>

										<v-stepper-content step="2">
											<v-container>
												<v-alert
												border="left"
												color="indigo"
												dark
												v-if="mandatoryPrestations"
												>
													Attention, au moins une des prestations marquée
													<v-icon>mdi-alert-decagram-outline</v-icon> doit être
													selectionnée.
												</v-alert>

												<v-data-table
												v-model="selectedPrestations"
												:items="mappedPrestations"
												:headers="prestationHeaders"
												hide-default-footer
												show-select
												item-key="id"
												class="outlined"
												:loading="prestations === []"
												:footer-props="{
													'disable-items-per-page': true
												}"
												@click:row="
													(item, row) => row.select(!row.isSelected)
												"
												disable-pagination
												>
													<template v-slot:item.code="{ item }">
														{{ item.code }}

														<v-tooltip
														bottom
														v-if="item.isMandatory === true"
														>
															<template v-slot:activator="{ on, attrs }">
																<v-icon
																v-bind="attrs"
																v-on="on"
																>
																	mdi-alert-decagram-outline
																</v-icon>
															</template>

															<span>Prestation obligatoire</span>
														</v-tooltip>
													</template>
												</v-data-table>
											</v-container>
										</v-stepper-content>
									</v-stepper-items>
								</v-stepper>
							</div>
						</div>
					</template>

					<template #right>
						<div class="bottom-containers">
							<div
							id="map-container"
							class="map-container"
							/>
						</div>
					</template>
				</DragCol>
			</template>
		</DragRow>

		<div
		v-show="isHelpShown"
		class="helpContainer"
		>
			<h2>Notice d'utilisation</h2>

			<p>
				Cette vue est un prototype permettant le paramétrage des prestations
				Amiante, HAP & HCT au couche par couche. <br/>
				Il est fort probable que des changements graphiques et fonctionnels
				arrivent régulièrement.
			</p>

			<ul>
				<li>
					Pour ajouter une/des prestation(s) sur une couche, sélectionner le
					prestataire et cliquer sur le nom de la couche
				</li>

				<li>
					Pour ajouter une/des prestation(s) sur toutes les couches de la
					carotte, cliquer sur le nom de la carotte
				</li>

				<li>
					Pour retirer une prestation, effectuer un double clic sur la
					prestation
				</li>

				<li>
					Pour changer la priorité d'une carotte, cliquer sur STD / RUSH
					(Eurofins uniquement)
				</li>
			</ul>

			<LowButtonSlot @click="isHelpShown = false">
				fermer
			</LowButtonSlot>
		</div>

		<div
		class="modal"
		v-if="displayModal"
		>
			<v-card
			flat
			outlined
			>
				<h3 class="tw-pb-[24px]">
					Validation des prestations
				</h3>

				<p>Êtes-vous sûr de vouloir valider ? Certaines couches ne comportent pas de prestation !</p>

				<div class="tw-col-span-12 tw-flex">
					<div class="tw-flex container-button tw-w-full">
						<ButtonSlot
						_theme="light-gray"
						class="button"
						@click="displayModal = false"
						>
							Annuler
						</ButtonSlot>

						<ButtonSlot
						class="button"
						@click="saveChanges(); displayModal = false"
						>
							Valider
						</ButtonSlot>
					</div>
				</div>
			</v-card>
		</div>
	</div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as turf from "@turf/turf";
import CorePrestation from "@/components/providers/Core.prestations";
import SendCoringCampaignToProviderValidationPopin from "@/components/providers/sendCoringCampaignToProviderValidation.popin.vue";
import {DragCol, DragRow} from "vue-resizer";

export default {
	components: {
		CorePrestation,
		DragCol,
		DragRow,
		SendCoringCampaignToProviderValidationPopin
	},
	data(){
		return {
			accessToken:
        "pk.eyJ1IjoiYWJhaWxseTUiLCJhIjoiY2t2dGd1ZXFoMHJhODJubHl2OG5ueGo2byJ9.KC7fg_kMFLW-FMsgoU7UPQ",
			step: 1,
			regExIsTime: new RegExp("^.*(H|J)$"),
			providers: [],
			prestations: [],
			layerCoef: 0,
			providerHeaders: [
				{
					text: "Choix des prestataires",
					align: "start",
					sortable: true,
					value: "name"
				}
			],
			prestationHeaders: [
				{
					text: "Code",
					sortable: true,
					value: "code"
				},
				{
					text: "Prestation",
					sortable: true,
					value: "nameShort"
				},
				{
					text: "Temps estimé",
					sortable: false,
					value: "estimatedDuration"
				}
			],
			selectedProvider: [null],
			selectedPrestations: [],
			mandatoryPrestations: false,
			campaign: {},
			cores: [],
			map: {},
			markers: [],
			isHelpShown: false,
			page: 1,
			itemsPerPage: 6,
			tempPrestations: null,
			displayModal: false,
			isValidated: false,
			isCoringCampaignSendDataPopinDisplay: false,
			isValidable: false
		};
	},
	computed: {
		applyPrestationsDisabled(){
			if(this.mandatoryPrestations === true){
				let mandatoryPrestationSelected = true;

				this.selectedPrestations.forEach(prestation => {
					if(prestation.isMandatory === true){
						mandatoryPrestationSelected = false;
					}
				});

				return mandatoryPrestationSelected;
			}

			return this.selectedPrestations.length === 0;
		},
		displayedCores(){
			let coresForCurrentPage = [];
			for(
				let i = this.itemsPerPage * (this.page - 1);
				i < this.itemsPerPage * this.page;
				i++
			){
				if(undefined !== this.cores[i]){
					coresForCurrentPage.push(this.cores[i]);
				}
			}
			return coresForCurrentPage;
		},
		pageCount(){
			return Math.ceil(this.allCount / this.itemsPerPage);
		},
		allCount(){
			return this.cores.length;
		},
		mappedSelectedPrestations(){
			return this.mappedPrestations.filter(
				presta =>
					-1 !== this.selectedPrestations.findIndex(p => p.id === presta.id)
			);
		},
		mappedPrestations(){
			let mappedPresta = this.prestations.map(el => {
				let nameSplitted = el.name.split(" ");
				let timeParts = nameSplitted.filter(el => this.regExIsTime.test(el));
				if(timeParts.length >= 1){
					el.estimatedDuration = timeParts[0];
					el.nameShort = nameSplitted
					.filter(el => !this.regExIsTime.test(el))
					.join(" ");
				}
				else {
					el.nameShort = el.name;
					el.estimatedDuration = "/";
				}
				return el;
			});
			mappedPresta.sort((a, b) => a.code - b.code);
			return mappedPresta;
		}
	},
	watch: {
		selectedProvider(){
			if(this.selectedProvider.length !== 0) this.getPrestations(this.selectedProvider[0].id);
		},
		cores(){
			let count = 0;
			if(this.cores.length !== 0){
				this.cores.forEach(core => {
					if(core.status === "validated"){
						if(core.layers !== undefined){
							core.layers.forEach(layer => {
								if(layer.prestations.length !== 0){
									this.isValidable = true;
									count++;
								}
							});
						}
						else {
							core.layersAndInterfaces.forEach(layer => {
								if(layer.type === "layer"){
									if(layer.layerPrestations.length !== 0){
										this.isValidable = true;
										count++;
									}
								}
							});
						}
					}
				});
			}
			if(count === 0){
				this.isValidable = false;
			}
		}
	},
	methods: {
		allLayerHasPrestation(){
			let allLayerHasPrestation = true;
			this.cores.forEach(core => {
				if(core.status === "validated"){
					if(core.layers !== undefined){
						core.layers.forEach(layer => {
							if(layer.prestations.length !== 0){
								this.isValidable = true;
							}
						});
					}
					else {
						core.layersAndInterfaces.forEach(layer => {
							if(layer.type === "layer"){
								if(layer.layerPrestations.length !== 0){
									this.isValidable = true;
								}
							}
						});
					}
				}
			});
			return allLayerHasPrestation;
		},
		checkIfValidatedStart(){
			this.cores.forEach(core => {
				if(core.layersAndInterfaces.length !== 0){
					core.layersAndInterfaces.forEach(layer => {
						if(layer.type === "layer"){
							if(layer.layerPrestations.length !== 0){
								this.isValidated = true;
								this.isValidable = true;
							}
						}
					});
				}
			});
		},
		applyPrestationsToAllLayers(){
			this.$root.$emit("addPrestations");
		},
		goBack(){
			this.$router.back();
		},
		changePage(value){
			this.tempPrestations = value;
		},
		removeAll(){
			this.cores.forEach(c => {
				c.layers.forEach(l => (l.prestations = []));
			});
		},
		saveChanges(){
			//this.saveCore();
			this.savePrestations();
		},
		saveCore(){
			this.cores.forEach(core => {
				this.$api.cores.edit(core.id, {...core, roadId: core.road.id});
			});
		},
		savePrestations(){
			let layers = this.cores.map(c => {
				if(undefined !== c.layers){
					return c.layers.map(l => {
						return {
							layerId: l.id,
							prestationsIds: l.prestations.map(p => p.prestation.id)
						};
					});
				}
			});

			this.$api.campaigns.editPrestationsDetail(
				this.$route.params.id,
				layers.flat(2).filter(layer => layer != undefined)
			).then(() => {

				this.isValidated = true;
			});
		},
		async fetchCores(){
			this.cores = await this.$api.cores.findByCampaign(this.$route.params.id, false, true);
			this.calcLayerCoef();
		},
		calcLayerCoef(){
			let layersThicknesses = this.cores.map(c => {
				const layers = c.layersAndInterfaces.filter(s => s.type === "layer");
				return layers.map(l => l.thickness);
			});

			const minLayer = Math.min(...layersThicknesses.flat(2));

			this.layerCoef = 40 / minLayer;
		},
		unselect(id){
			const index = this.selectedPrestations.findIndex(p => p.id === id);
			if(-1 === index) return;

			this.selectedPrestations.splice(index, 1);
		},
		async getCampaign(){
			this.campaign = await this.$api.campaigns.findById(this.$route.params.id);
		},
		async getProviders(){
			const providers = await this.$api.sites.getSiteProviders(
				this.campaign.workspace.site.id
			);
			this.providers = providers.map(ap => ap.provider);

			if(0 === this.providers.length){
				this.$router.push("/admin/organizations/" + this.site.organization.id);
				this.$toast(
					"warning",
					`L'agence ${this.site.name} nécessite un prestataire pour l'envoi des données`
				);
			}
		},
		async getPrestations(id){
			this.prestations = await this.$api.prestations.findByProvider(id);
			let foundMandatoryPrestation = false;

			this.prestations.forEach(prestation => {
				if(prestation.isMandatory === true){
					this.mandatoryPrestations = true;
					foundMandatoryPrestation = true;
				}

				if(foundMandatoryPrestation === false){
					this.mandatoryPrestations = false;
				}
			});
		},
		async validatePrestations(){
			await this.$api.campaigns.editPrestations(
				this.campaign,
				this.selectedPrestations.map(p => p.id)
			);

			this.$toast("success", "Prestations modifiées");
			this.$emit("close");
		},
		createMap(){
			let mapStyle = "mapbox://styles/mapbox/streets-v11";
			let center = {lng: 2.29, lat: 48.85};
			if(this.cores.length > 0){
				var features = turf.points(
					this.cores.map(core => [core.lng, core.lat])
				);
				center = turf.center(features).geometry.coordinates;
				this.$store.commit("campaign/set_current", {
					center: center
				});
			}
			mapboxgl.accessToken = this.accessToken;

			this.map = new mapboxgl.Map({
				container: "map-container",
				style: mapStyle,
				center: center,
				zoom: 12
			});

			this.createMarkers();
		},
		createMarkers(highlightedCore){
			this.markers.forEach(({marker}) => {
				marker.remove();
			});
			for(let i = 0; i < this.cores.length; i++){
				if(this.cores[i].status !== "cancelled"){
					const core = this.cores[i];

					const marker = document.createElement("div");
					marker.style.height = "20px";
					marker.style.width = "20px";
					marker.style.borderRadius = "10px";
					marker.style.display = "flex";
					marker.style.justifyContent = "center";
					marker.style.alignItems = "center";
					marker.style.color = "white";
					marker.style.backgroundColor =
          core.id === highlightedCore?.id ? "green" : "red";
					marker.innerHTML = core.number;

					let mapboxCoord = {
						lat: core.lat,
						lng: core.lng
					};

					this.markers.push({
						id: core.id,
						marker: new mapboxgl.Marker(marker)
						.setLngLat(mapboxCoord)
						.addTo(this.map)
					});

					if(core.id === highlightedCore?.id) this.map.jumpTo({
						center: [core.lng, core.lat],
						essential: true,
						zoom: 15
					});
				}
			}
		}
	},
	async created(){
		if(this.selectedPrestations.length > 0){
			this.selectedProvider = [this.selectedPrestations[0].provider];
			this.step = 2;
		}
		await this.fetchCores();
		await this.getCampaign();
		this.checkIfValidatedStart();
		this.createMap();
		this.getProviders();
	}
};
</script>

<style lang="scss">
@import "@/assets/scss/_extends.scss";
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.outlined {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.slider_col {
  background: #2b0803 !important;
  width: 12px !important;
  margin-right: -2px !important;
}
.slider_col::before {
  background: white !important;
}
.slider_col::after {
  background: white !important;
}
.slider_row {
  background: #2b0803 !important;
  height: 12px !important;
}
.slider_row::before {
  background: white !important;
}
.slider_row::after {
  background: white !important;
}
.drager_col {
  width: 100% !important;
  height: 100% !important;
}
.drager_row {
  width: 100% !important;
  height: 100% !important;
  margin-bottom: -8px;
}
.drager_right {
  padding: 0px !important;
}
.height100 {
  height: 100%;
}
.cores-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  padding: 10px;

  .coreScheme {
    margin: 10px;
    min-width: 80px;
    display: flex;
    flex-direction: column;

    div {
      // border-top: 1px solid black;
      // border-left: 1px solid black;
      // border-bottom: 1px solid black;
    }
  }

  /*.coreScheme.coreScheme:nth-last-child(1) div {
      border: 1px solid blue;
    }*/
}

.bottom-containers {
  display: flex;
  align-items: flex-start;
  height: 100%;

  .prestations-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .map-container {
    height: 100%;
    width: 100%;
  }
}
.left-bottom-container {
  display: flex;
  align-items: flex-start;
  height: calc(100% - 80px);
  .prestations-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
.selectedPrestas {
  display: flex;
  flex-wrap: wrap;

  .removePrestaIcon {
    &:hover {
      color: red;
    }
  }

  .singlePresta {
    margin: 5px;
  }
}

.recapCard {
  width: auto;

  p {
    text-align: left;
    margin: 0;
  }
}
.helpContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #eee;

  height: 500px;
  width: 500px;

  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  z-index: 100;
}
.pagination {
  z-index: 1;
  position: relative;
  bottom: 50px;
}
.modal {
	width: calc(100vw + 2px);
	height: calc(100vh + 2px);
	background: rgba(0, 0, 0, 0.75);
	position: fixed;
	top: -1px;
	left: -1px;
	z-index: 9;
	display: flex;
	justify-content: center;
	align-items: center;
		
	.v-card {
		width: auto;
		border: $border;
		padding: 60px 40px;
	}
	h3 {
		@extend %titleFont;

		text-align: center;
    }

    p {
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.4px;
      	text-align: center;
    }
	.container-button{
        display: flex;
        justify-content: space-between;
    }

	.button > span > p{
		color: white !important
	}
}
</style>
