<template>
	<div class="tw-h-full tw-p-[24px] tw-flex tw-flex-col tw-gap-[10px]">
		<HeaderSlot
		title="Résultats réseau"
		@clickBackButton="$router.push('/workspaces')"
		/>

		<CoringMacroMap
		class="tw-w-full tw-grow"
		>
			<CoringMacroFilterList/>

			<CoringMacroFilter/>
		</CoringMacroMap>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CoringMacroMap from "../../components/coring/macro/CoringMacroMap.vue";
import CoringMacroFilterList from "../../components/coring/macro/CoringMacroFilterList.vue";
import CoringMacroFilter from "../../components/coring/macro/CoringMacroFilter.vue";
export default {
	components: {
		CoringMacroMap,
		CoringMacroFilterList,
		CoringMacroFilter
	},
	computed: {
		...mapGetters("user", ["user"])
	},
	methods: {
		...mapActions("coringMacro", ["initStore", "purgeStore"]),
		...mapActions("user", ["waitLoadUser"]),
	},
	async mounted(){
		await this.waitLoadUser();
		this.initStore(this.user.userId);
	},
	destroyed(){
		this.purgeStore();
	}
};
</script>

<style>

</style>
