<template>
	<div class="auscultation_map__menu tw-select-none">
		<v-expansion-panels v-model="isOpen">
			<v-expansion-panel>
				<v-expansion-panel-header class="pt-0 pb-0">
					<h3>Liste des couches</h3>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<VCheckbox
					v-for="item of items"
					v-if="!item.disabled"
					color="darkgrey"
					class="tw-m-0 tw-text-[12px] tw-p-0"
					:label="item.name"
					:value="item.id"
					v-model="selected"
					danse
					hide-details
					:false-value="false"
					/>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
	name: "CoringMapMenu",
	data(){
		return {
			items: [],
			isOpen: 0,

		};
	},
	computed: {
		...mapGetters("coring", ["cores", "filter"]),
		
		selected: {
			set(arg){
				this.SET_FIlTER(arg);
			},
			get(){
				return this.filter;
			}
		}
	},
	methods: {
		...mapMutations("coring", ["SET_FIlTER"]),

		setlayerList(){
			let coreResultType = {
				asbestos: false,
				PAH: false,
				TH: false,
			};

			for(const {layersAndInterfaces: lai} of this.cores){
				for(let index = 0; index < lai.length; index += 2){
					if(lai[index].PAHResult !== null) coreResultType.PAH = true;
					if(lai[index].THResult !== null) coreResultType.TH = true;
					if(lai[index].asbestosResult !== null) coreResultType.asbestos = true;
				}
				if(coreResultType.asbestos === true && coreResultType.PAH === true && coreResultType.TH === true) break;
			}
			
			let items = [];
			for(const [key, value] of Object.entries(coreResultType)){
				items.push({
					name: this.$t(key),
					disabled: !value,
					id: key,
				});
			}
			this.items = items;
		}
	},
	mounted(){
		this.setlayerList();
	}
};
</script>

<style lang="scss">
.auscultation_map__menu {
  font-family: Roboto, sans-serif;
  .panel-header_overtext {
    padding: 16px 18px 10px 18px;
    font-size: 20px;
  }
  .v-expansion-panel-header {
    font-size: 15px;
  }
  .v-expansion-panel-content {
    &__wrap {
      max-height: 74vh;
      overflow-y: auto;
    }
  }
  .v-treeview-node {
    &__root {
      min-height: 30px;
    }
    &__children {
      min-height: 30px;
    }
  }

  position: absolute;
  top: 10px;
  left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 300px;
  .reset_button_container {
    display: flex;
    justify-content: center;
  }

  .miniCheckbox .mdi-checkbox-blank-outline::before,
  .miniCheckbox .mdi-checkbox-marked::before {
    transform: scale(0.8) !important;
  }

  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
    background: #ccc;
  }

  ::-webkit-scrollbar-thumb {
    background: #290703ee;
    -webkit-border-radius: 1ex;
    border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }
  
  .background {
    background-color: white;
  }
}
</style>
