<template>
	<section class="newCoringsValidationPopIn">
		<v-card
		flat
		outlined
		class="newCoringsValidationPopIn__card"
		>
			<h3>Valider l'envoi au laboratoire ?</h3>

			<v-card-text class="card_text">
				<p>
					Voulez-vous soumettre votre campagne et ses données à votre
					prestataire d'analyses ?
				</p>

				<v-spacer/>

				<p>Après envoi, cette campagne ne sera plus modifiable.</p>

				<p>
					<b>Nous vous rappelons que ceci est un engagement contractuel.</b>
				</p>

				<p
				v-if="!hasPrestations"
				class="danger"
				>
					Vous devez d'abord paramétrer les prestations de cette campagne
				</p>

				<br/>

				<p v-if="'6e42295a-a6e4-43b8-84d9-d37c5d9db7b3' === site.organization.id">
					<b>Pensez à réaliser votre commande sur GX.</b>
				</p>
			</v-card-text>

			<v-form @submit.prevent="confirmSending">
				<v-col
				cols="6"
				md="6"
				class="references"
				>
					<v-text-field
					tabindex="1"
					class="mt-1"
					label="Référence dossier*"
					outlined
					clearable
					v-model="form.nextroadRef"
					:rules="[$rules.required, $rules.max255]"
					counter="255"
					dense
					validate-on-blur
					/>

					<v-text-field
					tabindex="2"
					class="mt-1"
					label="Référence commande client*"
					outlined
					clearable
					v-model="form.customerOrderRef"
					:rules="[$rules.required, $rules.max255]"
					counter="255"
					dense
					validate-on-blur
					/>
				</v-col>
			</v-form>

			<v-card-actions class="justify-center">
				<MediumButtonSlot @click="closePopin">
					Annuler
				</MediumButtonSlot>

				<v-spacer/>

				<HighButtonSlot
				@click="redirectToPrestationSettings()"
				v-if="needButton"
				>
					{{ !hasPrestations ? 'Paramétrer les prestations' : 'Modifier les prestations' }}
				</HighButtonSlot>

				<HighButtonSlot
				:_disabled="
					false === hasPrestations ||
						null === form.nextroadRef ||
						null === form.customerOrderRef
				"
				@click="confirmSending"
				>
					Valider
				</HighButtonSlot>
			</v-card-actions>
		</v-card>
	</section>
</template>

<script>
import {mapActions} from "vuex";
export default {
	name: "NewCoringsValidationPopIn",
	props: [
		"site", "campaign", "needButton"
	],
	data(){
		return {
			hasPrestations: false,
			form: {
				nextroadRef: null,
				customerOrderRef: null
			}
		};
	},
	methods: {
		...mapActions("coring", ["setCampaignStatus", "updateCores"]),

		redirectToPrestationSettings(){
			this.$router.push({
				name: "coring-campaign-prestations",
				params: {id: this.$route.params.id}
			});
		},
		closePopin(){
			this.$emit("closePopin", false);
		},
		async confirmSending(){
			await this.$api.campaigns.editReferences(this.$route.params.id, {
				...this.form
			});
			await this.$api.coringCampaigns.sendToProvider(this.$route.params.id);
			await this.setCampaignStatus("sent_for_analysis");
			await this.updateCores();
			this.closePopin();
		},
		async hasCampaignPrestations(){
			const prestations = await this.$api.campaigns.getPrestations(
				this.$route.params.id
			);
			this.hasPrestations = prestations.length != 0;
		}
	},
	async mounted(){
		this.$root.$on("closeCurrent", this.closePopin);
		this.hasCampaignPrestations();
		if(this.campaign !== undefined){
			this.form.nextroadRef = this.campaign.nextroadRef;
			this.form.customerOrderRef = this.campaign.customerOrderRef;
		}
	},

};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.newCoringsValidationPopIn {
  @extend %popinView;

  .v-card {
    width: 50%;
    padding: 20px 20px;
  }

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .buttonsContainer button {
    margin: 5px;
  }

  .danger {
    color: red !important;
  }

  .references {
    margin: auto;
    padding-top: 0;
  }
}
</style>
