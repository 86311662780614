<template>
  <div class="tableContainer">
    <v-expansion-panels class="bordered" flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="text-center">
            <h2 class="mb-2 mt-2">Résultats amiante</h2>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            class="bordered"
            flat
            :headers="headers"
            :items="asbestosTableData"
            :items-per-page="-1"
            hide-default-footer
            :sort-by="['coreBusiness', 'coreLayerNumber']"
            :sort-desc="[false, false]"
          >
            <template
              v-slot:[`item.results.ASBESTOS.containsAsbestos`]="{ item }"
            >
              <v-chip :color="getColor(item.results.ASBESTOS)">
                {{ getText(item.results.ASBESTOS) }}
              </v-chip>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "amianteTable",
  props: {
    asbestosTableData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Carotte",
          align: "center",
          sortable: false,
          value: "businessId",
        },
        {
          text: "Numéro couche",
          align: "center",
          sortable: false,
          value: "number",
        },
        {
          text: "Matériau",
          align: "center",
          sortable: false,
          value: "materialName",
        },
        {
          text: "Route",
          align: "center",
          sortable: false,
          value: "core.road.name",
        },
        {
          text: "Sens",
          align: "center",
          sortable: false,
          value: "core.direction",
        },
        {
          text: "PR",
          align: "center",
          sortable: false,
          value: "core.landmark",
        },
        {
          text: "Abs",
          align: "center",
          sortable: false,
          value: "core.abscissa",
        },
        {
          text: "Latéralisation",
          align: "center",
          sortable: false,
          value: "core.lateralPosition",
        },
        {
          text: "Analyses",
          align: "center",
          sortable: false,
          value: "results.ASBESTOS.containsAsbestos",
        },
      ],
    };
  },
  methods: {
    getColor(asbestosResult) {
      if (null == asbestosResult) return "#333"
      if (asbestosResult.containsAsbestos) return "#ff0d0d";
      else return "#69b34c";
    },
    getText(asbestosResult) {
      if (null == asbestosResult) return "aucun résultat"
      if (true === asbestosResult.containsAsbestos) return "Amiante détecté";
      else return "Amiante non détecté";
    },
  },
};
</script>

<style lang="scss" scoped>
.tableContainer {
  .bordered {
    border: thin solid rgba(0, 0, 0, 0.12);
  }
}
</style>
