<template>
    <v-dialog
      v-model="dialog"
      persistent
      width="auto"
    >
      <v-card>
        <v-card-title class="text-h5">
          Cette campagne est désactivée
        </v-card-title>
        <v-card-text>Le site de cette campagne a été désactivé. Cependant vous pouvez toujours accéder aux livrables.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <medium-button-slot
            color="green-darken-1"
            variant="text"
            @click="destroy()"
          >
            Retour
          </medium-button-slot>
          <high-button-slot
            color="green-darken-1"
            variant="text"
            :_to="{ name: 'campaign-deliverables', params: { id: $route.params.id } }"
          >
            Accéder aux livrables
          </high-button-slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import HighButtonSlot from '../slots/buttons/High.button.slot.vue'
import MediumButtonSlot from '../slots/buttons/Medium.button.slot.vue'
export default {
  components: { HighButtonSlot, MediumButtonSlot },
  name: "SiteDeactivated",
  data () {
    return {
      dialog: true
    }
  },
  mounted() {
    console.log(this.campaign);
  },
  methods: {
    destroy() {
      this.dialog = false;
      this.$router.back();
      this.$destroy();
    }
  }
}
</script>
<style scoped>
.v-overlay__scrim {
  opacity: 0.90 !important;
  background-color: rgb(4 4 4) !important;
  border-color: rgb(0 0 0) !important;
}
</style>