<template>
  <div class="tableContainer">
    <v-expansion-panels class="bordered" flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="text-center">
            <h2 class="mb-2 mt-2">Résultats HAP</h2>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            class="bordered"
            flat
            :headers="headers"
            :items="pahTableData"
            :items-per-page="-1"
            hide-default-footer
            :sort-by="['coreBusiness', 'coreLayerNumber']"
            :sort-desc="[false, false]"
          >
            <template v-slot:[`item.results.PAH.sum`]="{ item }">
              <v-chip
                :class="
                  item.results.PAH !== null
                    ? item.results.PAH.category === '> 1000'
                      ? 'whiteText'
                      : ''
                    : ''
                "
                :color="
                  item.results.PAH !== null
                    ? getInfos(item.results.PAH.category).color
                    : '#333'
                "
              >
                {{
                  item.results.PAH !== null
                    ? item.results.PAH.textValue
                    : "aucun résultat"
                }}
              </v-chip>
            </template>
            <!-- <template
              v-slot:[`item.results.PAH.computed.PAHSumWithLQ`]="{ item }"
            >
              <v-chip
                :class="
                  item.results.PAH.computed.category === '> 1000'
                    ? 'whiteText'
                    : ''
                "
                :color="getInfos(item.results.PAH.computed.category).color"
              >
                {{ item.results.PAH.computed.PAHSumWithLQ.text }}
              </v-chip>
            </template> -->
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "HapTable",
  props: { pahTableData: { required: true, type: Array } },
  data() {
    return {
      headers: [],
      baseHeaders: [
        {
          text: "Carotte",
          align: "center",
          sortable: false,
          value: "core.carotte",
          width: "200px",
        },
        {
          text: "Numéro couche",
          align: "center",
          sortable: false,
          value: "number",
        },
        {
          text: "Matériau",
          align: "center",
          sortable: false,
          value: "materialName",
        },
        {
          text: "Route",
          align: "center",
          sortable: false,
          value: "core.road.name",
        },
        {
          text: "Sens",
          align: "center",
          sortable: false,
          value: "core.direction",
        },
        {
          text: "PR",
          align: "center",
          sortable: false,
          value: "core.landmark",
        },
        {
          text: "Abs",
          align: "center",
          sortable: false,
          value: "core.abscissa",
        },
        {
          text: "Latéralisation",
          align: "center",
          sortable: false,
          value: "core.lateralPosition",
        },
        {
          text: "Somme des HAP (excluant LQ)",
          align: "center",
          sortable: false,
          value: "results.PAH.sum",
          width: "150px",
        },
      ],
    };
  },
  methods: {
    addHeaders() {
      this.headers = this.baseHeaders;
      const items = this.pahTableData;

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (null === item.results.PAH) continue;

        const composition = item.results.PAH.composition;

        for (const [elementName] of Object.entries(composition)) {
          this.headers.push({
            text: elementName,
            align: "center",
            sortable: false,
            value: "results.PAH.composition." + elementName,
          });
        }
        return;
      }
    },
    getInfos(category) {
      switch (category) {
        case "[0; 50]":
          return {
            color: "#69b34c",
            text: "[0; 50]",
          };
        case "]50; 500]":
          return {
            color: "#ff8e15",
            text: "]50; 500]",
          };
        case "]500; 1000]":
          return {
            color: "#ff0d0d",
            text: "]500; 1000]",
          };
        case "> 1000":
          return {
            color: "#000",
            text: "> 1000",
          };
      }
    },
    getColor(item) {
      let value = item.replace("< ", "");
      value = Number(value.replace(",", "."));
      if (value <= 50) return "#69b34c";
      if (value <= 500) return "#ff8e15";

      return value < 1000 ? "#ff0d0d" : "#000000";
    },
  },
  watch: {
    pahTableData: {
      deep: true,
      immediate: true,
      handler() {
        this.addHeaders();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tableContainer {
  .bordered {
    border: thin solid rgba(0, 0, 0, 0.12);
  }
}

.whiteText {
  color: white !important;
}
</style>
